import React from "react"

type MessageChargementProps = {
	texte: string,
}

const MessageChargement: React.FunctionComponent<MessageChargementProps> = ({ texte }: MessageChargementProps) => {
	return (
		<p className="sp-message-attente">
			{ texte }
			<span> .</span><span> .</span><span> .</span>
		</p>
	)
}

export default MessageChargement

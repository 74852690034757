import { useContext } from "react"
import { UtilisateurCourantContext } from "./UtilisateurCourantContext"
import { ConnectedUser } from "../../../services/Authentification/Authentification"

export function useUtilisateurCourant (): ConnectedUser {
	const utilisateurCourant = useContext(UtilisateurCourantContext)
	if (!utilisateurCourant) {
		throw Error("Vous devez utiliser useUtilisateurCourant à l'intérieur d'un composant <Authentifié />")
	}
	return utilisateurCourant
}

import ChampAutoCompletion, { Suggestion } from "../../ui/ChampAutoCompletion/ChampAutoCompletion"
import React, { useContext } from "react"
import { MoteurDeRechercheDeDonneesReferentielContext } from "../../App"
import LieuDInscriptionValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/LieuDInscriptionValidation"
import { ReponseUgle } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/types/UgleReponseHttp"
import { extraireNumeroDepartementEntreParentheses } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentielAxios"

type ChampReferentielLieuDInscriptionProps = {
	lieuDInscription: string
	codeInseeLieuDInscription: string
	onChange: (suggestion: ReponseUgle) => any
	validation: LieuDInscriptionValidation
	messageErreurFormulaire?: string,
}

const ChampReferentielLieuDInscription: React.FunctionComponent<ChampReferentielLieuDInscriptionProps> = ({ lieuDInscription, codeInseeLieuDInscription, validation, onChange, messageErreurFormulaire }: ChampReferentielLieuDInscriptionProps) => {
	const moteurDeRechercheDeDonneesReferentiel = useContext(MoteurDeRechercheDeDonneesReferentielContext)
	const compléterSuggestionsUgle: (terme: string) => Promise<Suggestion<ReponseUgle>[]> = (terme: string) => {
		return moteurDeRechercheDeDonneesReferentiel.completerUgle(terme)
			.catch(() => [])
	}

	const uneValeurAÉtéSelectionnéeDansLaListe = (communeUgle: ReponseUgle) => {
		onChange(communeUgle)
	}

	const suggestionLieUDInscriptionVide = { key: "", label:"", values: { codeCommune: "", codeUgle: "", libelleCommune: "", libelleUgle: "" } }
	return (
		<ChampAutoCompletion
			compléter={ compléterSuggestionsUgle }
			id={ "lieuDInscription" }
			label={ "Commune de vote" }
			messageErreurFormulaire={ messageErreurFormulaire }
			name={ "lieuDInscription" }
			onSelect={ uneValeurAÉtéSelectionnéeDansLaListe }
			suggestionReinitialisante={ suggestionLieUDInscriptionVide.values }
			texteAide="Exemple : Sainte-Cécile-Les-Vignes (84)"
			valeurInitiale={ lieuDInscription + extraireNumeroDepartementEntreParentheses(codeInseeLieuDInscription) }
			validation={ validation }
		/>
	)
}

export default ChampReferentielLieuDInscription

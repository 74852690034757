import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"
import DepotDePiecesJustificatives from "./DepotDePiecesJustificatives"

const DepotAxiosDePiecesJustificatives = (): DepotDePiecesJustificatives => {
	return {
		televerser,
		supprimer
	}
}

const televerser = async (idUsager: string, naturePieceJustificative: string, pieceJustificative: File,  position: string): Promise<string> => {
	const formData = new FormData()
	formData.set("file", pieceJustificative)
	const result = await axios.post(
		`${BACKEND_URL}/pieces-justificatives/${idUsager}/${naturePieceJustificative}/${position}`,
		formData
	)
	return result.data.id
}

const supprimer = async ( idUsager: string, idPieceJustificative?: string): Promise<void> => {
	await axios.delete(
		`${BACKEND_URL}/pieces-justificatives/${idUsager}/${idPieceJustificative}`
	)
}

export default DepotAxiosDePiecesJustificatives

import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation, { REGEX_TOUS_LES_CARACTERES_AVEC_UNE_VALEUR_HEXADECIMALE_COMPRISE_ENTRE_00_ET_1F_OU_7F } from "./Validation"

export default class Complement1AdresseValidation implements Validation {
	protected labelDuChamp = "complément d'adresse 1"
	readonly attributsDeValidation = {
		"aria-required": false,
		"maxLength": "50"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.trim().length > 50) {
			return MessagesErreursValidation.COMPLEMENT_ADRESSE_TROP_LONG
		}
		if (REGEX_TOUS_LES_CARACTERES_AVEC_UNE_VALEUR_HEXADECIMALE_COMPRISE_ENTRE_00_ET_1F_OU_7F.test(valeur)) {
			return MessagesErreursValidation.COMPLEMENT_ADRESSE_AVEC_CARACTERE_SPECIAL
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

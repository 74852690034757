import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation from "./Validation"

const CODE_POSTAL_REGEX = /^[0-9]+$/i

export default class CodePostalDeResidenceValidation implements Validation {
	protected labelDuChamp = "code postal"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "5"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.trim().length === 0) {
			return MessagesErreursValidation.CODE_POSTAL_NON_RENSEIGNE
		}
		if (!CODE_POSTAL_REGEX.test(valeur)) {
			return MessagesErreursValidation.CODE_POSTAL_AVEC_CHIFFRE
		}
		if (valeur.trim().length !== 5) {
			return MessagesErreursValidation.CODE_POSTAL_NON_VALIDE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


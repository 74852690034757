import ChampListeDeroulante, { Option } from "../../ui/ChampListeDeroulante/ChampListeDeroulante"
import React from "react"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
import { listePays } from "../../../services/ValidateurDeDemarche/validateursParChamp/NationaliteValidation"

export type nationalite = {
	nationalite: string
}

interface ChampListeDeroulantePaysNationaliteProps {
	valeurSelectionnee: string
	messageErreurFormulaire?: string
	mettreAJourLaValeurDuPaysDeNationalite: (valeurSelectionnee: Partial<nationalite>) => void
	validation?: Validation
}

const ChampListeDeroulantePaysNationalite: React.FunctionComponent<ChampListeDeroulantePaysNationaliteProps> = ({ messageErreurFormulaire, mettreAJourLaValeurDuPaysDeNationalite, validation, valeurSelectionnee }: ChampListeDeroulantePaysNationaliteProps) => {
	const modifierChoix = ( choix: string ) => {
		mettreAJourLaValeurDuPaysDeNationalite({ nationalite: choix })
	}

	const optionsDisponibles: Option[] = [...listePays].filter(pays => pays.valeur !== "FRA")
	optionsDisponibles.unshift({ label: "Sélectionnez un pays", valeur: "" })

	return (
		<ChampListeDeroulante
			autoComplete = "country-name"
			id =  "nationalite"
			label = "Pays de nationalité"
			messageErreurFormulaire={ messageErreurFormulaire }
			modifierChoix={ modifierChoix }
			name = "nationalite"
			options= { optionsDisponibles }
			required={ true }
			valeurInitiale={ valeurSelectionnee }
			validation= { validation }
		/>
	)
}

export default ChampListeDeroulantePaysNationalite

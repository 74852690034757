import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

export default class SexeValidation implements Validation {
  attributsDeValidation = {}

  estValide(valeur: string): boolean {
  	return !this.messageErreur(valeur)
  }
  messageErreur(valeur: string): string {
  	if (valeur !== "Masculin" && valeur !== "Féminin") {
  		return MessagesErreursValidation.SEXE_OBLIGATOIRE
  	}
  	return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
  }
}

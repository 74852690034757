import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"


export default class CommuneDeNaissanceAvecNumeroDepartementValidation implements Validation {
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur === "") {
			return MessagesErreursValidation.COMMUNE_DE_NAISSANCE_INCONNU
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { DemarcheContexte } from "../../pages/Demarche/DemarcheContext"

const ScrollEnDebutDePage: React.FunctionComponent = () => {
	const { pathname } = useLocation()
	const { champAFocaliser } = useContext(DemarcheContexte)

	useEffect(() => {
		if (!champAFocaliser) {
			window.scrollTo(0, 0)
		}
	}, [pathname])

	return null
}

export default ScrollEnDebutDePage

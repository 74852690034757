enum MessagesErreursValidation {
	PAS_D_ERREUR_DE_VALIDATION = "",
	ERREUR_GENERIQUE_POUR_MOCK = "Ce champ est obligatoire",
	NOM_AVEC_CHIFFRE = "Votre nom ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	NOM_OBLIGATOIRE = "Votre nom est obligatoire pour pouvoir vérifier votre identité",
	NOM_TROP_LONG = "Votre nom ne peut pas contenir plus de 100 caractères. Merci de corriger votre saisie",
	NOM_AVEC_CARACTERE_SPECIAL = "Votre nom contient un caractère inattendu. Merci de corriger votre saisie",
	NOM_D_USAGE_AVEC_CHIFFRE = "Votre nom d'usage ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	NOM_D_USAGE_TROP_LONG = "Votre nom d'usage ne peut pas contenir plus de 100 caractères. Merci de corriger votre saisie",
	NOM_D_USAGE_AVEC_CARACTERE_SPECIAL = "Votre nom d'usage contient un caractère inattendu. Merci de corriger votre saisie",
	PRENOM_OBLIGATOIRE = "Votre prénom est obligatoire pour pouvoir vérifier votre identité",
	PRENOM_AVEC_CHIFFRE = "Votre prénom ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	PRENOM_AVEC_CARACTERE_SPECIAL = "Votre prénom contient un caractère inattendu. Merci de corriger votre saisie",
	PRENOM_TROP_LONG = "Votre prénom ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	PRENOM_SECONDAIRE_A_INDIQUER = "Si vous avez un autre prénom indiquez-le sinon supprimez ce champ",
	DATE_DE_NAISSANCE_INCOMPLETE_MAUVAIS_FORMAT = "Merci d'écrire votre date de naissance avec le format attendu par exemple 02/2000 ou 2000",
	DATE_DE_NAISSANCE_COMPLETE_MAUVAIS_FORMAT = "Merci d'écrire votre date de naissance avec le format attendu par exemple 24/12/2000",
	DATE_DE_NAISSANCE_COMPLETE_OBLIGATOIRE = "Votre date de naissance est obligatoire pour pouvoir vérifier votre identité",
	DATE_DE_NAISSANCE_INCOMPLETE_OBLIGATOIRE = "Votre date de naissance est obligatoire pour pouvoir vérifier votre identité",
	DATE_INVALIDE = "Cette date n'existe pas. Merci de corriger votre saisie",
	AGE_MOINS_DE_18_ANS = "Vous devez avoir au moins 18 ans pour réaliser cette démarche",
	AGE_PLUS_DE_125_ANS = "Avez-vous 125 ans ou plus ? Si oui toutes nos félicitations ! Si non merci de corriger votre saisie",
	COMMUNE_DE_NAISSANCE_AVEC_CARACTERE_SPECIAL = "Votre commune de naissance contient un caractère inattendu. Merci de corriger votre saisie",
	COMMUNE_DE_NAISSANCE_INCONNU = "Recherchez et choisissez votre commune de naissance dans la liste proposée",
	COMMUNE_DE_NAISSANCE_TROP_LONGUE = "Votre commune de naissance ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	PAYS_DE_NAISSANCE_INCONNU = "Recherchez et choisissez votre pays de naissance dans la liste proposée",
	PAS_DE_PAYS_DE_NATIONALITE_EUROPEENNE_RENSEIGNE = "Recherchez et choisissez votre pays de nationalité dans la liste proposée",
	SEXE_OBLIGATOIRE = "Sélectionnez votre sexe pour compléter votre identité",
	LIEU_INSCRIPTION_INCONNU = "Recherchez et choisissez votre commune de vote dans la liste proposée",
	TYPE_DE_LISTE_NON_RENSEIGNE = "Sélectionnez les élections pour lesquelles vous souhaitez voter",
	CHOIX_DU_TYPE_JUSTIFICATIF_NON_RENSEIGNE = "La sélection et la transmission de votre justificatif sont obligatoires pour poursuivre votre demande",
	PIECE_JUSTIFICATIVE_OBLIGATOIRE = "Vous n'avez pas transmis votre justificatif. Utilisez le bouton 'Ajouter un fichier ou une photo' ci-dessous",
	PIECE_JUSTIFICATIVE_VOLUMINEUSE = "La taille de votre fichier dépasse le maximum autorisé de 2 Mo. Veuillez sélectionner un nouveau fichier",
	PIECE_JUSTIFICATIVE_MAUVAIS_FORMAT = "Votre fichier n’est pas accepté. Veuillez vérifier son format d’enregistrement et son extension (PDF, JPEG, JPG, PNG). Écrivez le nom de votre fichier avec uniquement des chiffres et des lettres, des tirets haut et bas et 100 caractères maximum. Par exemple : mon-fichier_2023.pdf.",
	PIECE_JUSTIFICATIVE_VIDE = "Votre fichier est vide ou mal enregistré. Veuillez sélectionner un nouveau fichier",
	ADRESSE_RNVP_OBLIGATOIRE = "Recherchez et choisissez votre adresse dans la liste proposée ou sélectionnez \"Écrire mon adresse\"",
	COMMUNE_DE_RESIDENCE_AVEC_CARACTERE_SPECIAL = "Le nom de la commune contient un caractère inattendu. Merci de corriger votre saisie",
	COMMUNE_DE_RESIDENCE_TROP_LONGUE = "Le nom de la commune ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	COMMUNE_DE_RESIDENCE_AVEC_CHIFFRE = "Le nom de la commune ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	COMMUNE_DE_NAISSANCE_AVEC_CHIFFRE = "Votre commune de naissance ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	COMMUNE_DE_RESIDENCE_NON_RENSEIGNEE = "Indiquez la commune par exemple Saint-Jacques-de-la-Lande",
	COMMUNE_DE_RESIDENCE_NON_RENSEIGNEE_ETRANGER = "Indiquez la commune par exemple Berlin",
	COMMUNE_DE_NAISSANCE_NON_RENSEIGNEE = "Votre commune de naissance est obligatoire pour pouvoir vérifier votre identité",
	CODE_POSTAL_NON_VALIDE = "Le code postal en France doit contenir 5 chiffres par exemple 35136. Merci de corriger votre saisie",
	CODE_POSTAL_NON_VALIDE_ETRANGER = "Le code postal à l'étranger ne doit pas contenir plus de 20 chiffres par exemple STHL 1ZZ. Merci de corriger votre saisie",
	CODE_POSTAL_AVEC_CHIFFRE = "Le code postal ne peut contenir que des chiffres. Merci de corriger votre saisie",
	CODE_POSTAL_CARACTERE_INATTENDU_ETRANGER = "Le code postal contient un caractère inattendu. Merci de corriger votre saisie",
	CODE_POSTAL_NON_RENSEIGNE = "Indiquez votre code postal par exemple 35136",
	NUMERO_DE_VOIE_AVEC_CARACTERE_SPECIAL = "Le numéro de voie contient un caractère inattendu. Merci de corriger votre saisie",
	NUMERO_DE_VOIE_TROP_LONG = "Le numéro de voie ne peut pas contenir plus de 20 caractères. Merci de corriger votre saisie",
	LIBELLE_DE_VOIE_NON_RENSEIGNE = "Indiquez un nom de voie par exemple : Rue Louise Michel. Sinon indiquez un lieu-dit ci-dessous",
	LIBELLE_DE_VOIE_NON_RENSEIGNE_ETRANGER = "Indiquez un nom de voie par exemple : Postdamer Straße. Sinon indiquez un lieu-dit ci-dessous",
	LIBELLE_DE_VOIE_TROP_LONG = "Le nom de voie ne peut pas contenir plus de 150 caractères. Merci de corriger votre saisie",
	LIBELLE_DE_VOIE_AVEC_CARACTERE_SPECIAL = "Le nom de la voie contient un caractère inattendu. Merci de corriger votre saisie",
	LIEU_DIT_TROP_LONG = "Le lieu-dit ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	LIEU_DIT_NON_RENSEIGNE = "Indiquez un lieu-dit par exemple : Ferme de la Gautrais. Sinon indiquez un nom de voie ci-dessus",
	LIEU_DIT_NON_RENSEIGNE_ETRANGER = "Indiquez un lieu-dit par exemple : Burg Island. Sinon indiquez un nom de voie ci-dessus",
	LIEU_DIT_AVEC_CARACTERE_SPECIAL = "Le lieu-dit contient un caractère inattendu. Merci de corriger votre saisie",
	COMPLEMENT_ADRESSE_TROP_LONG = "Le complément d'adresse ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	COMPLEMENT_ADRESSE_AVEC_CARACTERE_SPECIAL = "Le complément d'adresse contient un caractère inattendu. Merci de corriger votre saisie",
	CHANGEMENT_COMMUNE_OBLIGATOIRE = "Veuillez sélectionner la réponse qui correspond à votre situation",
	MAIL_MAUVAIS_FORMAT = "Merci d’écrire votre email avec le format attendu par exemple monnom@example.com",
	MAIL_OBLIGATOIRE = "Votre email est obligatoire pour traiter votre demande",
	MAIL_TROP_LONG = "Votre email ne peut pas contenir plus de 254 caractères. Merci de corriger votre saisie",
	NUMERO_DE_TELEPHONE_TROP_LONG = "Votre numéro de téléphone ne peut pas contenir plus de 25 caractères. Merci de corriger votre saisie",
	NUMERO_TELEPHONE_MAUVAIS_FORMAT = "Merci d’écrire votre numéro de téléphone avec l’un des formats attendus par exemple 0123456789 ou 06 12 34 56 78 ou +34 123 456 789",
	PAYS_NON_RENSEIGNE = "Indiquez votre pays par exemple Estonie",
	PAYS_TROP_LONG = "Le pays ne peut pas contenir plus de 50 caractères. Merci de corriger votre saisie",
	PAYS_AVEC_CHIFFRE = "Le pays ne peut pas contenir de chiffre. Merci de corriger votre saisie",
	PAYS_AVEC_CARACTERE_SPECIAL = "Le pays contient un caractère inattendu. Merci de corriger votre saisie",
}

export default MessagesErreursValidation

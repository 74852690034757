import PrenomValidation from "./PrenomValidation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const PRENOM_REGEX = /^[- a-zœæ'àâäçéèêëîïôöùûüÿ]+$/i

export default class PrenomSecondaireValidation extends PrenomValidation{
	protected labelDuChamp = "prénom"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "50"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur !== undefined) {
			if (valeur.trim().length === 0) {
				return MessagesErreursValidation.PRENOM_SECONDAIRE_A_INDIQUER
			}
			if (valeur.length > 50) {
				return MessagesErreursValidation.PRENOM_TROP_LONG
			}
			if (!PRENOM_REGEX.test(valeur)) {
				const contientUnChiffre = /\d/
				if (contientUnChiffre.test(valeur)) {
					return MessagesErreursValidation.PRENOM_AVEC_CHIFFRE
				}
				return MessagesErreursValidation.PRENOM_AVEC_CARACTERE_SPECIAL
			}
		}
		return ""
	}
}

import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

export default class ChangementCommuneValidation implements Validation {
  attributsDeValidation = {}

  estValide(valeur: string): boolean {
  	return !this.messageErreur(valeur)
  }
  messageErreur(valeur: string): string {
  	if (valeur !== "oui" && valeur !== "non") {
  		return MessagesErreursValidation.CHANGEMENT_COMMUNE_OBLIGATOIRE
  	}
  	return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
  }
}

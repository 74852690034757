import React, { Fragment, useContext, useEffect } from "react"
import EtapeDemarche from "../../ui/EtapeDemarche/EtapeDemarche"
import { DemarcheContexte } from "../Demarche/DemarcheContext"
import Cookies from "universal-cookie"

const Recapitulatif: React.FunctionComponent = () => {
	const cookies = new Cookies()
	const numeroTeledossierExisteDansLesCookies = cookies.get("numeroTeledossier") !== undefined
	const { envoiEnCours, recapitulatif, modifierLePremierBloc, modifierLeTroisiemeBloc, modifierLeDeuxiemeBloc, envoyerLaDemarche, messageErreurEnvoi, revoirLaConfirmation } = useContext(DemarcheContexte)

	useEffect(() => {
		document.title = "Récapitulatif de votre demande d’inscription sur les listes électorales - service-public.fr"
	}, [])

	return (

		<Fragment>
			<div className="sp-demarche-step">

				<EtapeDemarche
					id="etape-1"
					numeroEtape="1"
					titre="Vérifier votre identité"
				/>

				<div className="sp-demarche-step-recap">

					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="fr-fi-account-line">
						</span>
					</p>

					<p>
						Vous êtes : <br />
						{ (recapitulatif.prenomsEtNomDUsage !== "") && (
							<Fragment>
								<strong>{ recapitulatif.prenomsEtNomDUsage }</strong>,
							</Fragment>
						) }
						{ recapitulatif.sexe === "Féminin" &&
						" née "
						}
						{ recapitulatif.sexe === "Masculin" &&
						" né "
						}
						<strong>{ recapitulatif.nomDeFamille }</strong> <br className="sp-display-md"/>
						{ recapitulatif.prefixeDateDeNaissance } <strong>{ recapitulatif.dateDeNaissance } </strong>
						<strong>à { recapitulatif.communeDeNaissanceAvecDepartement }</strong> en <strong>{ recapitulatif.paysDeNaissance }</strong> de nationalité <strong>{ recapitulatif.nationalité }</strong>
					</p>

					<hr/>
					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-file-user-line">
						</span>
					</p>

					<p>
						Vos justificatifs : <br />
						<strong className="sp-break-word">
							{ recapitulatif.nomJustificatifIdentite }
							{ (recapitulatif.nomJustificatifIdentite2 !== "") &&
									<Fragment>
										<br />
									</Fragment>
							}
							{ recapitulatif.nomJustificatifIdentite2 }
						</strong>
					</p>
					{ !numeroTeledossierExisteDansLesCookies &&
						<p className="sp-demarche-step-recap-modifier">
							<button
								className="fr-btn fr-btn--secondary fr-btn--sm sp-btn--no-border"
								onClick={ modifierLePremierBloc }
								title="Modifier votre identité"
								type="button"
							>
								Modifier
								<span
									aria-hidden={ true }
									className="ri-edit-line ri--sm"
								>
								</span>
							</button>
						</p>
					}
				</div>
			</div>

			<div className="sp-demarche-step">

				<EtapeDemarche
					id="etape-2"
					numeroEtape="2"
					titre="Vérifier votre souhait d’inscription"
				/>

				<div className="sp-demarche-step-recap">

					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-file-list-3-line">
						</span>
					</p>

					<p>
						Vous souhaitez vous inscrire sur les listes électorales de : <br/>
						<strong className="sp-break-word">{ `${ recapitulatif.lieuDInscriptionAvecDepartement }` }</strong>
					</p>
					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-home-4-line">
						</span>
					</p>

					<p>
						Votre adresse dans la commune : <br/>
						<strong>{ `${ recapitulatif.adresse }` }</strong>
					</p>
					<hr/>
					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-file-user-line">
						</span>
					</p>
					<p>
						Vos justificatifs :
						<br />
						<strong className="sp-break-word">
							{ recapitulatif.nomJustificatifDomicile }
							{ (recapitulatif.nomJustificatifDomicile2 !== "") &&
							<Fragment>
								<br />
							</Fragment>
							}
							{ recapitulatif.nomJustificatifDomicile2 }
						</strong>
						{ recapitulatif.nomDeclarationEcrite && (
							<Fragment>
								<br/>
								<strong className="sp-break-word">{ recapitulatif.nomDeclarationEcrite }</strong>
							</Fragment>
						) }
					</p>
					{ !numeroTeledossierExisteDansLesCookies &&
						<p className="sp-demarche-step-recap-modifier">
							<button
								className="fr-btn fr-btn--secondary fr-btn--sm sp-btn--no-border"
								onClick={ modifierLeDeuxiemeBloc }
								title="Modifier votre souhait d'inscription"
								type="button"
							>
								Modifier
								<span
									aria-hidden={ true }
									className="ri-edit-line ri--sm"
								>
								</span>
							</button>
						</p> }

				</div>
			</div>

			<div className="sp-demarche-step">

				<EtapeDemarche
					id="etape-3"
					numeroEtape="3"
					titre="Vérifier vos coordonnées"
				/>

				<div className="sp-demarche-step-recap">

					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-file-user-line">
						</span>
					</p>
					<p>
						Votre adresse postale : <br/>
						<strong>{ `${ recapitulatif.adresseDeContact }` }</strong>
					</p>
					<hr/>

					<p className="sp-demarche-step-recap-icone">
						<span
							aria-hidden="true"
							className="ri-links-line">
						</span>
					</p>

					<p>
						Pour vous contacter : <br/>
						<strong className="sp-break-word">{ recapitulatif.adresseEmail }</strong><br/>
						<strong className="sp-break-word">{ recapitulatif.numeroTelephone }</strong>
					</p>
					{ !numeroTeledossierExisteDansLesCookies &&
						<p className="sp-demarche-step-recap-modifier">
							<button
								className="fr-btn fr-btn--secondary fr-btn--sm sp-btn--no-border"
								onClick={ modifierLeTroisiemeBloc }
								title="Modifier vos coordonnées"
								type="button"
							>
								Modifier
								<span
									aria-hidden={ true }
									className="ri-edit-line ri--sm"
								>
								</span>
							</button>
						</p>
					}
				</div>
			</div>

			<ul className="fr-btns-group fr-mb-2w fr-mt-6w">
				<li>
					{ numeroTeledossierExisteDansLesCookies ? (
						<button
							className="fr-btn"
							onClick={ revoirLaConfirmation }
						>
							{ "Revoir la confirmation de votre demande" }
						</button>
					) : (!envoiEnCours && (
						<button
							className="fr-btn"
							onClick={ envoyerLaDemarche }
							type="submit"
						>
							{ "Envoyer votre demande" }
						</button>
					) )
						 }
					{ envoiEnCours && (
						<button
							aria-label="Envoi en cours"
							className="fr-btn bouton-envoi-disabled"
							disabled={ true }
							type="submit"
						>
							<div className="dot-flashing"></div>
							<span
								aria-live="polite"
								className="fr-sr-only"
							>
								{ "L'envoi de votre demande en cours" }
							</span>
						</button>
					) }
				</li>
			</ul>

			{ messageErreurEnvoi && <p>{ messageErreurEnvoi }</p> }

		</Fragment>
	)
}

export default Recapitulatif

import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

export default class ListeDElectionEuropeenneValidation implements Validation {
	attributsDeValidation = {}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}
	messageErreur(valeur: string): string {
		if (valeur === "Liste principale") {
			return MessagesErreursValidation.TYPE_DE_LISTE_NON_RENSEIGNE
		}
		if (valeur !== "Élections européennes" && valeur !== "Élections municipales" && valeur !== "Élections européennes et municipales") {
			return MessagesErreursValidation.TYPE_DE_LISTE_NON_RENSEIGNE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

import React from "react"
import ReactDOM from "react-dom"

import "@gouvfr/dsfr/dist/css/dsfr.css"
import "./sp-gouvfr/dile.scss"
import "./sp-gouvfr/sp-icons/fonts/remix.eot"
import "./sp-gouvfr/sp-icons/fonts/remix.ttf"
import "./sp-gouvfr/sp-icons/fonts/remix.woff"
import "./sp-gouvfr/sp-icons/fonts/remix.svg"
import "@gouvfr/dsfr/dist/js/dsfr.module.min.js"

import App from "./App/App"

ReactDOM.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
	document.getElementById("root")
)

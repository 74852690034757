import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"


export default class AdresseRedresseeValidation implements Validation {
  protected labelDuChamp = "Adresse"
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur === undefined || valeur.trim() === "") {
			return MessagesErreursValidation.ADRESSE_RNVP_OBLIGATOIRE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

import { useContext, useEffect, useState } from "react"
import { MoteurDeRechercheDeDonneesReferentielContext } from "../../App"

export function useVerifierSiRnvpEstOK(): boolean {
	const [rnvpOK, setRnvpOK] = useState(true)
	const moteurDeRechercheDeDonneesReferentiel = useContext(MoteurDeRechercheDeDonneesReferentielContext)
	useEffect(() => {
		(async () => {
			const RNVPOk = await moteurDeRechercheDeDonneesReferentiel.verifierSiRNVPEstOk()
			setRnvpOK(RNVPOk)
		})()
	}, [])
	return rnvpOK
}


import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"

interface SupervisionInterface {
	loggerLaConnexionDunUsager: (idUsager: string, accessToken: string) => Promise<void>
}

const Supervision = (): SupervisionInterface => {
	return {
		loggerLaConnexionDunUsager
	}
}

const loggerLaConnexionDunUsager = async (idUsager: string, accessToken: string): Promise<void> => {
	await axios.get(
		`${BACKEND_URL}/supervision/connexion?id_usager=${idUsager}`,
		{ headers: { authorization: `Bearer ${accessToken}` } }
	)
}

export default Supervision

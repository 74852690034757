import ChampsDuFormulaireDemarche from "../../../services/GestionnaireDeDemarche/types/ChampsDuFormulaireDemarche"
import { DonnéesRécapitulatif } from "../../../services/GestionnaireDeDemarche/types/DonnéesRécapitulatif"
import React, { Dispatch, SetStateAction } from "react"
import { créerDémarcheVide, créerRecapitulatif } from "../../../test/fixtures/demarche"

export interface DemarcheContexteInterface {
	valeursFormulaireEnregistrées: ChampsDuFormulaireDemarche
	recapitulatif: DonnéesRécapitulatif
	sauvegarderDonnéesValides: (valeurs: ChampsDuFormulaireDemarche) => void
	champAFocaliser: string
	setChampAFocaliser: Dispatch<SetStateAction<string>>
	modifierLePremierBloc: () => void
	modifierLeDeuxiemeBloc: () => void
	modifierLeTroisiemeBloc: () => void
	envoyerLaDemarche: () => void
	messageErreurEnvoi: string
	envoiEnCours: boolean
	revoirLaConfirmation: () => void
}

export const DemarcheContexte = React.createContext<DemarcheContexteInterface>({
	champAFocaliser: "",
	setChampAFocaliser: () => { return },
	valeursFormulaireEnregistrées: créerDémarcheVide(),
	recapitulatif: créerRecapitulatif(créerDémarcheVide()),
	envoyerLaDemarche(): void { return },
	messageErreurEnvoi: "",
	modifierLeDeuxiemeBloc(): void { return },
	modifierLePremierBloc(): void { return },
	modifierLeTroisiemeBloc(): void { return },
	sauvegarderDonnéesValides(): void { return },
	envoiEnCours: false,
	revoirLaConfirmation(): void { return }
})

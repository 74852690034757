import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

type Pays = {
	label: string
	valeur: string
	nationalité: string
}

export const listePays: Pays[] = [
	{ label: "FRANCE", valeur: "FRA", nationalité: "française" },
	{ label: "ALLEMAGNE", valeur: "DEU", nationalité: "allemande" },
	{ label: "AUTRICHE", valeur: "AUT", nationalité: "autrichienne" },
	{ label: "BELGIQUE", valeur: "BEL", nationalité: "belge" },
	{ label: "BULGARIE", valeur: "BGR", nationalité: "bulgare" },
	{ label: "CHYPRE", valeur: "CYP", nationalité: "chypriote" },
	{ label: "CROATIE", valeur: "HRV", nationalité: "croate" },
	{ label: "DANEMARK", valeur: "DNK", nationalité: "danoise" },
	{ label: "ESPAGNE", valeur: "ESP", nationalité: "espagnole" },
	{ label: "ESTONIE", valeur: "EST", nationalité: "estonienne" },
	{ label: "FINLANDE", valeur: "FIN", nationalité: "finlandaise" },
	{ label: "GRECE", valeur: "GRC", nationalité: "grecque" },
	{ label: "HONGRIE", valeur: "HUN", nationalité: "hongroise" },
	{ label: "IRLANDE ou EIRE", valeur: "IRL", nationalité: "irlandaise" },
	{ label: "ITALIE", valeur: "ITA", nationalité: "italienne" },
	{ label: "LETTONIE", valeur: "LVA", nationalité: "lettonne" },
	{ label: "LITUANIE", valeur: "LTU", nationalité: "lituanienne" },
	{ label: "LUXEMBOURG", valeur: "LUX", nationalité: "luxembourgeoise" },
	{ label: "MALTE", valeur: "MLT", nationalité: "maltaise" },
	{ label: "PAYS-BAS", valeur: "NLD", nationalité: "néerlandaise" },
	{ label: "POLOGNE", valeur: "POL", nationalité: "polonaise" },
	{ label: "PORTUGAL", valeur: "PRT", nationalité: "portugaise" },
	{ label: "ROUMANIE", valeur: "ROU", nationalité: "roumaine" },
	{ label: "SLOVAQUIE", valeur: "SVK", nationalité: "slovaque" },
	{ label: "SLOVENIE", valeur: "SVN", nationalité: "slovène" },
	{ label: "SUEDE", valeur: "SWE", nationalité: "suédoise" },
	{ label: "TCHEQUE (République)", valeur: "CZE", nationalité: "tchèque" }
]


export default class NationaliteValidation implements Validation {
	attributsDeValidation = {}
	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur === "") {
			return MessagesErreursValidation.PAS_DE_PAYS_DE_NATIONALITE_EUROPEENNE_RENSEIGNE
		}
		const valeursPossibles = listePays.map(pays => pays.valeur)
		if (!valeursPossibles.includes(valeur)) {
			return MessagesErreursValidation.PAS_DE_PAYS_DE_NATIONALITE_EUROPEENNE_RENSEIGNE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


import React, { Fragment, useEffect } from "react"
import Cookies from "universal-cookie"
import { format } from "date-fns"
import { useLocation } from "react-router-dom"
import { DonnéesRécapitulatif } from "../../../services/GestionnaireDeDemarche/types/DonnéesRécapitulatif"
import { fr } from "date-fns/locale"
const FORMAT_DATE_DEPOT_DEMANDE = "PPPP à HH:mm"

const Confirmation: React.FunctionComponent = () => {
	const cookies = new Cookies()
	const location = useLocation()
	const data: string = location.state as string
	const donnéesRecap: DonnéesRécapitulatif = JSON.parse(data)
	const dateHeureDepotAuFormatFrancais = format(new Date(cookies.get("dateHeureDepot")), FORMAT_DATE_DEPOT_DEMANDE, { locale: fr })

	useEffect(() => {
		document.title = "Confirmation de votre demande d’inscription sur les listes électorales - service-public.fr"
	}, [])

	return (
		<Fragment>
			<div>
				<div className="sp-demarche-step">
					<h2 className="sp-demarche-step-titre">
						<span className="sp-demarche-step-titre-nombre ">
							<span
								aria-hidden="true"
								className="fr-fi-check-line"
							>
							</span>
						</span>
						<span className="sp-demarche-step-titre-label">Votre demande a été envoyée</span>
					</h2>
				</div>
				<div className="fr-callout sp-callout-recap">
					<span
						aria-hidden="true"
						className="ri-arrow-left-right-line"
					>
					</span>
					<p>
						<strong>{ donnéesRecap.prenoms } { donnéesRecap.nomDUsage === "" ? donnéesRecap.nomDeFamille : donnéesRecap.nomDUsage }</strong>,
					votre demande d’inscription sur les listes électorales <strong>N° { cookies.get("numeroTeledossier") } </strong>
					a été transmise à la commune de
						<strong> { donnéesRecap.lieuDInscriptionAvecDepartement } </strong>
					le <strong>{ dateHeureDepotAuFormatFrancais }</strong>
					</p>
				</div>
				<h2 className="sp-icon-text fr-text--lg fr-text--regular sp-accent fr-mt-6w fr-mb-3w">
					<span
						aria-hidden="true"
						className="fr-fi-calendar-line"
					>
					</span>
				&nbsp;Et ensuite
				</h2>
				<ul>
					<li>La commune de <strong>{ donnéesRecap.lieuDInscriptionAvecDepartement }</strong> va étudier votre demande</li>
					<li>Nous vous informerons de l&#39;avancée de votre démarche<strong> par email</strong></li>
				</ul>
				<h2 className="sp-icon-text fr-text--lg fr-text--regular sp-accent fr-mt-6w fr-mb-3w">
					<span
						aria-hidden="true"
						className="ri-map-pin-2-line"
					>
					</span>
				Votre contact
				</h2>
				<p>En cas de besoin vous pouvez contacter votre mairie.</p>
				<p>
					<a
						className="fr-btn fr-btn--secondary sp-btn-icon"
						href="https://lannuaire.service-public.fr/recherche?whoWhat=mairie"
						rel="noopener noreferrer"
						target="_blank"
						title="Voir les informations de ma mairie dans l’annuaire service-public.fr - Nouvelle fenêtre"
					>
						<span
							aria-hidden="true"
							className="ri-map-pin-2-line"
						>
						</span>
					Voir les informations de ma mairie dans <br/>l’annuaire service-public.fr
					</a>
				</p>
				<div className="sp-demarche-recap"></div>
			</div>
			<ul className="fr-btns-group fr-mb-2w fr-mt-6w">
				<li>
					<a
						className="fr-btn"
						href="/donnerSonAvis">
					Terminer
					</a>
				</li>
			</ul>
		</Fragment>
	)
}

export default Confirmation

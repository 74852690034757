import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,9}$/i


export default class AdresseEmailValidation implements Validation {
	protected labelDuChamp = "adresse email personnelle"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "254"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur != undefined) {
			if (valeur.trim().length === 0) {
				return MessagesErreursValidation.MAIL_OBLIGATOIRE
			}
			if (valeur.length > 254) {
				return MessagesErreursValidation.MAIL_TROP_LONG
			}
			if (!EMAIL_REGEX.test(valeur)) {
				return MessagesErreursValidation.MAIL_MAUVAIS_FORMAT
			}
		}
		else {
			return MessagesErreursValidation.MAIL_OBLIGATOIRE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

import React, { ChangeEvent, useState } from "react"
import classNames from "classnames"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"

type ChampTextuelProps = {
	id: string
	label: string,
	largeur?: "petit" | ""
	validation?: Validation
	texteAide?: string,
	name: string,
	autoComplete?: string
	valeur?: string,
	type?: "text" | "email" | "tel",
	messageErreurFormulaire?: string,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	required?: boolean
	maxLength?: number
}

const ChampTextuel: React.FunctionComponent<ChampTextuelProps> = ({ id, label, validation, name, autoComplete, texteAide = "", valeur = "", messageErreurFormulaire, onChange, largeur = "", type = "text", required = false, maxLength }: ChampTextuelProps) => {
	const descriptionId = `description-${ id }`
	const errorId = `error-${ id }`
	const ariaDescribedBy = texteAide ? [descriptionId, errorId].join(" ") : errorId
	const [messageErreur, setMessageErreur] = useState<string | undefined>()
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)

	const attributsDeValidation = validation ? validation.attributsDeValidation : {}
	const messageErreurAffiche = aEteModifie ? messageErreur : messageErreurFormulaire

	function verifierValeur(e: any) {
		if (validation) {
			setAEteModifie(true)
			if (validation.estValide(e.target.value)) {
				setMessageErreur(undefined)
				e.target.setCustomValidity("")
			} else {
				setMessageErreur(validation.messageErreur(e.target.value))
				e.target.setCustomValidity(validation.messageErreur(e.target.value))
			}
		}
	}

	const classesDuGroupe = classNames("fr-input-group", {
		"fr-input-group--error": Boolean(messageErreurAffiche)
	})
	const classesInput = classNames("fr-input", {
		"fr-input--error": Boolean(messageErreurAffiche),
		"fr-co-l3": largeur === "petit"
	})

	return (
		<div className={ classesDuGroupe }>
			<label
				className="fr-label"
				htmlFor={ id }
			>
				{ label }
			</label>
			{ texteAide &&
			<p
				className="fr-hint-text"
				id={ descriptionId }
			>
				{ texteAide }
			</p>
			}
			<input
				className={ classesInput }
				{ ...attributsDeValidation }
				aria-describedby={ ariaDescribedBy }
				aria-invalid={ Boolean(messageErreurAffiche) || undefined }
				aria-required={ required }
				autoComplete={ autoComplete }
				id={ id }
				maxLength={ maxLength }
				name={ name }
				onBlur={ verifierValeur }
				onChange={ onChange }
				type={ type }
				value={ valeur }
			/>
			<p
				className="fr-error-text"
				hidden={ Boolean(!messageErreurAffiche) }
				id={ errorId }
				role="alert"
			>
				{ messageErreurAffiche }
			</p>
		</div>
	)
}

export default ChampTextuel

import React, { Fragment } from "react"
import styles from "./PieceJustificative.module.css"
import classNames from "classnames"

const TexteAideJustificatifDeDeclarationEcrite : React.FunctionComponent = () => {
	return (
		<Fragment>
			<h4 className="fr-text">Votre déclaration écrite</h4>
			<div>
				<p>Votre déclaration sur l’honneur<strong> doit préciser : </strong></p>
				<ul className={ classNames(styles.pieceJustificativeTexteAideBulletPoint, "is-list") }>
					<li><span>Votre <strong>nationalité</strong> et votre <strong>adresse en France</strong></span></li>
					<li><span>Que vous n’êtes <strong>pas privé du droit de vote</strong> dans ce pays</span></li>
				</ul>
				<p>Et si vous souhaitez voter pour les <strong>élections européennes</strong> :</p>
				<ul className={ classNames(styles.pieceJustificativeTexteAideBulletPoint, "is-list") }>
					<li><span>Que vous <strong>voterez</strong> pour ces élections <strong>uniquement en France</strong></span></li>
					<li><span>La commune ou la collectivité ou la circonscription de votre <strong>dernière inscription</strong> sur les <strong>listes électorales</strong> de <strong>votre pays</strong></span></li>
				</ul>
			</div>
		</Fragment>
	)
}

export default TexteAideJustificatifDeDeclarationEcrite

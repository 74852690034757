import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const AGE_MINIMUM_POUR_FAIRE_LA_DEMANDE = 18
const AGE_MAXIMUM_POUR_FAIRE_LA_DEMANDE = 125

export default class DateDeNaissanceIncompleteValidation implements Validation {
	protected labelDuChamp = "dateDeNaissanceIncomplete"
	readonly attributsDeValidation = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if ((valeur.trim().length === 0)) {
			return MessagesErreursValidation.DATE_DE_NAISSANCE_INCOMPLETE_OBLIGATOIRE
		}

		if (!this.vérifierLeFormatMMYYYYOuYYYY(valeur)) {
			return MessagesErreursValidation.DATE_DE_NAISSANCE_INCOMPLETE_MAUVAIS_FORMAT
		}

		const dateIncompleteAvecMoisEtAnnée: boolean = valeur.includes("/")

		if (dateIncompleteAvecMoisEtAnnée) {
			const [moisDeNaissanceDecoupe, anneeDeNaissanceDecoupe] = valeur.split("/")

			const moisInvalide = this.vérifierSiLeMoisEstInvalide(moisDeNaissanceDecoupe)
			if (moisInvalide) {
				return MessagesErreursValidation.DATE_INVALIDE
			}

			if (this.vérifierAgeInferieurA18AnsUniquementAvecAnneeEtMois(anneeDeNaissanceDecoupe, moisDeNaissanceDecoupe)) {
				return MessagesErreursValidation.AGE_MOINS_DE_18_ANS
			}

			if (this.vérifierAgeSuperieurA125AnsUniquementAvecAnneeEtMois(anneeDeNaissanceDecoupe, moisDeNaissanceDecoupe)) {
				return MessagesErreursValidation.AGE_PLUS_DE_125_ANS
			}
		}

		if (!dateIncompleteAvecMoisEtAnnée){
			const annnéeRenseignée: string = valeur
			if (this.vérifierAgeMoins18AnsUniquementAvecAnnee(annnéeRenseignée)) {
				return MessagesErreursValidation.AGE_MOINS_DE_18_ANS
			}
			if (this.vérifierAgePlus125AnsUniquementAvecAnnee(annnéeRenseignée)) {
				return MessagesErreursValidation.AGE_PLUS_DE_125_ANS
			}
		}

		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}

	private vérifierSiLeMoisEstInvalide(moisDeNaissanceDecoupe: string) {
		const moisDeNaissance: number = +moisDeNaissanceDecoupe
		const moisInvalide: boolean = moisDeNaissance < 1 || moisDeNaissance > 12
		return moisInvalide
	}

	private vérifierAgeMoins18AnsUniquementAvecAnnee(valeur: string) {
		const anneeDeNaissance: number = +valeur
		const anneeActuelle = new Date().getFullYear()
		return anneeDeNaissance + AGE_MINIMUM_POUR_FAIRE_LA_DEMANDE > anneeActuelle
	}

	private vérifierAgePlus125AnsUniquementAvecAnnee(valeur: string) {
		const anneeDeNaissance: number = +valeur
		const anneeActuelle = new Date().getFullYear()
		return anneeDeNaissance + AGE_MAXIMUM_POUR_FAIRE_LA_DEMANDE < anneeActuelle
	}

	private vérifierAgeInferieurA18AnsUniquementAvecAnneeEtMois(annee: string, mois: string) {
		const anneeDeNaissance: number = +annee
		const moisDeNaissance: number = +mois
		const dateActuelle = new Date()
		const anneeActuelle = dateActuelle.getFullYear()
		const moisActuel = dateActuelle.getMonth() + 1

		const usagerEstDansLAnnéeDeSes18Ans = anneeActuelle - anneeDeNaissance === AGE_MINIMUM_POUR_FAIRE_LA_DEMANDE
		if (usagerEstDansLAnnéeDeSes18Ans) {
			return moisDeNaissance > moisActuel
		}
		return anneeDeNaissance + AGE_MINIMUM_POUR_FAIRE_LA_DEMANDE > anneeActuelle
	}

	private vérifierAgeSuperieurA125AnsUniquementAvecAnneeEtMois(annee: string, mois: string) {
		const anneeDeNaissance: number = +annee
		const moisDeNaissance: number = +mois
		const dateActuelle = new Date()
		const anneeActuelle = dateActuelle.getFullYear()
		const moisActuel = dateActuelle.getMonth() + 1

		const usagerEstDansLAnnéeDeSes18Ans = anneeActuelle - anneeDeNaissance === AGE_MINIMUM_POUR_FAIRE_LA_DEMANDE
		if (usagerEstDansLAnnéeDeSes18Ans) {
			return moisDeNaissance > moisActuel
		}
		return anneeDeNaissance + AGE_MAXIMUM_POUR_FAIRE_LA_DEMANDE < anneeActuelle
	}


	private vérifierLeFormatMMYYYYOuYYYY(valeur: string) {
		const patternDateDeNaissanceIncomplete = RegExp(/^\d{2}[/]\d{4}$|^\d{4}$/)
		const laValeurEstValide: boolean = patternDateDeNaissanceIncomplete.test(valeur)
		return laValeurEstValide
	}
}

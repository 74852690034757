import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation from "./Validation"

export default class PaysDeNaissanceValidation implements Validation {
	protected labelDuChamp = "pays de naissance"
	readonly attributsDeValidation = {
		"aria-required": true,
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.trim().length === 0) {
			return MessagesErreursValidation.PAYS_DE_NAISSANCE_INCONNU
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


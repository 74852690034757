import ChampAutoCompletion, { Suggestion } from "../../ui/ChampAutoCompletion/ChampAutoCompletion"
import React, { useContext, useState } from "react"
import { MoteurDeRechercheDeDonneesReferentielContext } from "../../App"
import { AdresseRedressée } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/types/AdresseRedressée"
import AdresseRedresseeValidation
	from "../../../services/ValidateurDeDemarche/validateursParChamp/AdresseRedresseeValidation"

type ChampAdresseRedresseeProps = {
	adresseRedressee: string
	numero: string,
	libelleDeVoie: string,
	codePostal: string,
	commune: string,
	filtreCommune: string,
	filtreNumeroDepartement: string,
	onChange: (suggestion: AdresseRedressée) => void,
	disabled?: boolean,
	label: string,
	id: string,
	name: string,
	messageErreurFormulaire?: string,
	validation: AdresseRedresseeValidation,
	viderChamp?: boolean,
}

const ChampAdresseRedressee: React.FunctionComponent<ChampAdresseRedresseeProps> = ({ id, name, label, numero, libelleDeVoie, codePostal, commune, filtreCommune,	filtreNumeroDepartement, onChange,	disabled, validation, messageErreurFormulaire, viderChamp	}: ChampAdresseRedresseeProps) => {
	const moteurDeRechercheDeDonneesReferentiel = useContext(MoteurDeRechercheDeDonneesReferentielContext)
	const compléterSuggestionsAdresse: (terme: string) => Promise<Suggestion<AdresseRedressée>[]> = (terme: string) => {
		return moteurDeRechercheDeDonneesReferentiel.redresserAdresse(terme, filtreCommune, filtreNumeroDepartement).catch(() => [])
	}

	const [messageErreur, setMessageErreur] = useState("")
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)
	const messageErreurAffiche = aEteModifie ? messageErreur : messageErreurFormulaire

	const uneValeurAÉtéSelectionnéeDansLaListe = (adresseRedressée: AdresseRedressée) => {
		setAEteModifie(true)
		setMessageErreur("")
		onChange(adresseRedressée)
	}

	const suggestionAdresseRedresséeVide = { key: "", label: "", values: { codePostal: "", nomDeVoie: "", numéroDeVoie: "", ville: "" } }

	return (
		<ChampAutoCompletion
			compléter={ compléterSuggestionsAdresse }
			disabled={ disabled }
			id={ id }
			label={ label }
			messageErreurFormulaire={ messageErreurAffiche }
			name={ name }
			onSelect={ uneValeurAÉtéSelectionnéeDansLaListe }
			suggestionReinitialisante={ suggestionAdresseRedresséeVide.values }
			texteAide="Exemple : 10 Route de Lagarde 84290 Sainte-Cécile-les Vignes"
			valeurInitiale={ [numero, libelleDeVoie, codePostal, commune].join(" ").trim() }
			validation={ validation }
			viderChamp={ viderChamp }
		/>
	)
}

export default ChampAdresseRedressee

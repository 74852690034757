import ChampAutoCompletion, { Suggestion } from "../../ui/ChampAutoCompletion/ChampAutoCompletion"
import React, { useContext } from "react"
import { MoteurDeRechercheDeDonneesReferentielContext } from "../../App"
import PaysDeNaissanceValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/PaysDeNaissanceValidation"
import { PaysDeNaissance } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/types/PaysDeNaissanceReponseHttp"

interface ChampReferentielPaysDeNaissanceProps {
	paysDeNaissance: string
	onChange: (pays: PaysDeNaissance) => void
	validation: PaysDeNaissanceValidation
	messageErreurFormulaire?: string
}

const ChampReferentielPaysDeNaissance: React.FunctionComponent<ChampReferentielPaysDeNaissanceProps> = ({ paysDeNaissance, onChange, validation, messageErreurFormulaire }: ChampReferentielPaysDeNaissanceProps) => {
	const moteurDeRechercheDeDonneesReferentiel = useContext(MoteurDeRechercheDeDonneesReferentielContext)
	const suggérerPaysDeNaissance: (terme: string) => Promise<Suggestion<PaysDeNaissance>[]> = (terme: string) => {
		return moteurDeRechercheDeDonneesReferentiel.rechercherPaysDeNaissance(terme)
	}

	const mettreAJourValeur = (paysDeNaissance: PaysDeNaissance) => {
		onChange(paysDeNaissance)
	}

	const suggestionPaysDeNaissanceVide: Suggestion<PaysDeNaissance> = { key: "", label: "", values: { code: "", libelle: "" } }

	return (
		<ChampAutoCompletion
			compléter={ suggérerPaysDeNaissance }
			id={ "paysDeNaissance" }
			label={ "Pays de naissance" }
			messageErreurFormulaire={ messageErreurFormulaire }
			name={ "paysDeNaissance" }
			onSelect={ mettreAJourValeur }
			suggestionReinitialisante={ suggestionPaysDeNaissanceVide.values }
			valeurInitiale={ paysDeNaissance }
			validation={ validation }
		/>
	)
}

export default ChampReferentielPaysDeNaissance

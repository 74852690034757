type TypeValeur = string | string[]

export default interface Validation {
  estValide (value: TypeValeur | undefined): boolean
  messageErreur (value: TypeValeur | undefined): string
  readonly attributsDeValidation: ValidationAttributes | undefined
}

export type ValidationAttributes = {
  required?: boolean
  "aria-required"?: boolean
}

// eslint-disable-next-line no-control-regex
export const REGEX_TOUS_LES_CARACTERES_AVEC_UNE_VALEUR_HEXADECIMALE_COMPRISE_ENTRE_00_ET_1F_OU_7F = /[\x00-\x1F\x7F]+/

import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"



export default class NomDeFamilleValidation implements Validation {
  protected labelDuChamp = "nom"
	protected NOM_REGEX = /^[- a-zœæ'àâäçéèêëîïôöùûüÿ]+$/i
	protected LONGEUR_MAXI_NOM = 100
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
  	return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
  	if (valeur.trim().length === 0) {
  		return MessagesErreursValidation.NOM_OBLIGATOIRE
  	}
  	if (valeur.length > this.LONGEUR_MAXI_NOM) {
  		return MessagesErreursValidation.NOM_TROP_LONG
  	}
  	if (!this.NOM_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.NOM_AVEC_CHIFFRE
			}
  		return MessagesErreursValidation.NOM_AVEC_CARACTERE_SPECIAL
  	}
  	return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

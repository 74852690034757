import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation, { REGEX_TOUS_LES_CARACTERES_AVEC_UNE_VALEUR_HEXADECIMALE_COMPRISE_ENTRE_00_ET_1F_OU_7F } from "./Validation"


export default class LibelleDeVoieValidation implements Validation {
	protected labelDuChamp = "libelle de voie"
	readonly attributsDeValidation = {
		"aria-required": false,
		"maxLength": "150"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.trim().length > 150) {
			return MessagesErreursValidation.LIBELLE_DE_VOIE_TROP_LONG
		}
		if (REGEX_TOUS_LES_CARACTERES_AVEC_UNE_VALEUR_HEXADECIMALE_COMPRISE_ENTRE_00_ET_1F_OU_7F.test(valeur)) {
			return MessagesErreursValidation.LIBELLE_DE_VOIE_AVEC_CARACTERE_SPECIAL
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


import { useState, useEffect } from "react"
import { useDebounce }  from "use-debounce"

const DELAI_EN_MILLISECONDES_POUR_REBONDIR = 200

type SuggestionHook<S> = [S[], SuggérerPour, Annuler]
type SuggérerPour = (préfixe: string) => void
type Annuler = () => void

export default function useSuggestions<S extends any> (compléter: (terme: string) => Promise<S[]>): SuggestionHook<S> {
	const [préfixe, setPréfixe] = useState<string>("")
	const [debouncedPréfixe] = useDebounce(préfixe, DELAI_EN_MILLISECONDES_POUR_REBONDIR)
	const [suggestions, setSuggestions] = useState<S[]>([])

	async function rafraichirSuggestions (terme: string) {
		setSuggestions(terme ? await compléter(terme) : [])
	}

	useEffect(() => {
		rafraichirSuggestions(debouncedPréfixe)
	}, [debouncedPréfixe])

	const annulerSuggestions = () => rafraichirSuggestions("")

	return [suggestions, setPréfixe, annulerSuggestions]
}


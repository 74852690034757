import NomDeFamilleValidation from "./NomDeFamilleValidation"
import MessagesErreursValidation from "./MessagesErreursValidation"


export default class NomDUsageValidation extends NomDeFamilleValidation {
  protected labelDuChamp = "nom d'usage"
	readonly attributsDeValidation = {}

	estValide(valeur: string): boolean {
  	return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
  	if (valeur.trim().length === 0) {
  		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
  	}
		if (valeur.length > this.LONGEUR_MAXI_NOM) {
			return MessagesErreursValidation.NOM_D_USAGE_TROP_LONG
		}
		if (!this.NOM_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.NOM_D_USAGE_AVEC_CHIFFRE
			}
			return MessagesErreursValidation.NOM_D_USAGE_AVEC_CARACTERE_SPECIAL
		}
  	return super.messageErreur(valeur)
	}
}


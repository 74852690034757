import React, { Fragment } from "react"

const TexteAideJustificatifIdentite : React.FunctionComponent = () => {
	return (
		<Fragment>
			<h4 className="fr-text">Quel justificatif fournir ?</h4>
			<p>
				Les justificatifs d’
				<strong>identité et nationalité</strong> les plus courants sont : la
				<strong> carte nationale d’identité</strong>, le
				<strong> passeport</strong>, le
				<strong> titre de séjour</strong>.
			</p>
			<p>
				La liste de
				<strong> tous les justificatifs recevables</strong> pour cette démarche est
				<strong> disponible sur service-public.fr</strong>
			</p>
		</Fragment>
	)
}

export default TexteAideJustificatifIdentite

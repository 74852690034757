import React from "react"

const Entête: React.FunctionComponent = () => {
	return (
		<div className="fr-header__body">
			<div className="fr-container">
				<div className="fr-header__body-row">

					<div className="fr-header__brand fr-enlarge-link">
						<div className="fr-header__brand-top">

							<div className="fr-header__logo">
								<p className="fr-logo">
									République <br />Française
								</p>
							</div>

						</div>

						<div className="fr-header__service">
							<a
								href="https://www.service-public.fr/"
								title="Accueil Service-Public.fr"
							>
								<p className="fr-header__service-title">Service-Public.fr</p>
							</a>
							<p className="fr-header__service-tagline">Le site officiel de l’administration française</p>
						</div>

					</div>

				</div>
			</div>
		</div>
	)
}

export default Entête

import React from "react"

type EtapeDemarcheProps = {
	id: string
	numeroEtape: string
	titre: string
}

const EtapeDemarche: React.FunctionComponent<EtapeDemarcheProps> = ({ id, numeroEtape, titre }: EtapeDemarcheProps) => {
	return (
		<div className="sp-demarche-step">
			<h2
				className="sp-demarche-step-titre"
				id={ id }>
				<span className="sp-demarche-step-titre-nombre"> { numeroEtape }</span>
				<span className="sp-demarche-step-titre-label">{ titre }</span>
			</h2>
		</div>
	)
}

export default EtapeDemarche

import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"


export default class LieuDInscriptionValidation implements Validation {
  protected labelDuChamp = "Lieu d’inscription"
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur === "") {
			return MessagesErreursValidation.LIEU_INSCRIPTION_INCONNU
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

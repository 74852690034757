import ChampsDuFormulaireDemarche from "./types/ChampsDuFormulaireDemarche"

const convertirVersDemarchePayload = (demarcheValeursChamps
:
ChampsDuFormulaireDemarche
):
{
	nomDeFamille: string;
	nomDUsage: string;
	prenom: string;
	prenom2: string | undefined;
	prenom3: string | undefined;
	sexe: string;
	dateDeNaissance: string;
	paysDeNaissance: string;
	codeInseePaysDeNaissance: string;
	codeInseeCommuneDeNaissance: string;
	idPieceJustificative: string;
	idPieceJustificative2: string;
	idJustificatifDomicile: string;
	idJustificatifDomicile2: string;
	lieuDInscription: string;
	codeInseeLieuDInscription: string;
	communeAdresse: string;
	codePostalAdresse: string;
	numeroDeVoieAdresse: string;
	libelleDeVoieAdresse: string;
	lieuDit: string;
	complement1Adresse: string;
	complement2Adresse: string;
	nationalite: string;
	paysAdresseDeContact: string;
	communeAdresseDeContact: string;
	codePostalAdresseDeContact: string;
	numeroDeVoieAdresseDeContact: string;
	libelleDeVoieAdresseDeContact: string;
	lieuDitAdresseDeContact: string;
	complement1AdresseDeContact: string;
	complement2AdresseDeContact: string
	communeDeNaissance: string;
	idDeclarationEcrite: string | undefined;
	adresseIdentiquePourProgrammeCandidats: string;
	changementCommune: string;
	typeDeListe: string;
	adresseEmail: string;
	numeroTelephone: string;
} => {
	return {
		nationalite: demarcheValeursChamps.nationalite,
		typeDeListe: demarcheValeursChamps.typeDeListe,
		nomDeFamille: demarcheValeursChamps.nomDeFamille,
		nomDUsage: demarcheValeursChamps.nomDUsage,
		sexe: demarcheValeursChamps.sexe,
		prenom: demarcheValeursChamps.prenom,
		prenom2: demarcheValeursChamps.prenom2,
		prenom3: demarcheValeursChamps.prenom3,
		dateDeNaissance: demarcheValeursChamps.dateDeNaissance || demarcheValeursChamps.dateDeNaissanceIncomplète || "",
		paysDeNaissance: demarcheValeursChamps.paysDeNaissance,
		codeInseePaysDeNaissance: demarcheValeursChamps.codeInseePaysDeNaissance,
		communeDeNaissance: demarcheValeursChamps.communeDeNaissance,
		codeInseeCommuneDeNaissance: demarcheValeursChamps.codeInseeCommuneDeNaissance,
		lieuDInscription: demarcheValeursChamps.lieuDInscription,
		codeInseeLieuDInscription: demarcheValeursChamps.codeInseeLieuDInscription,
		communeAdresse: demarcheValeursChamps.adresseDeRattachementCommune,
		codePostalAdresse: demarcheValeursChamps.adresseDeRattachementCodePostal,
		numeroDeVoieAdresse: demarcheValeursChamps.adresseDeRattachementNumero,
		lieuDit: demarcheValeursChamps.adresseDeRattachementLieuDit,
		libelleDeVoieAdresse: demarcheValeursChamps.adresseDeRattachementLibelleDeVoie,
		complement1Adresse: demarcheValeursChamps.adresseDeRattachementComplement1Adresse,
		complement2Adresse: demarcheValeursChamps.adresseDeRattachementComplement2Adresse,
		adresseIdentiquePourProgrammeCandidats: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats,
		paysAdresseDeContact: demarcheValeursChamps.adresseDeContactPays,
		communeAdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementCommune : demarcheValeursChamps.adresseDeContactCommune,
		codePostalAdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementCodePostal : demarcheValeursChamps.adresseDeContactCodePostal,
		numeroDeVoieAdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementNumero : demarcheValeursChamps.adresseDeContactNumero,
		lieuDitAdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementLieuDit : demarcheValeursChamps.adresseDeContactLieuDit,
		libelleDeVoieAdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementLibelleDeVoie : demarcheValeursChamps.adresseDeContactLibelleDeVoie,
		complement1AdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementComplement1Adresse : demarcheValeursChamps.adresseDeContactComplement1Adresse,
		complement2AdresseDeContact: demarcheValeursChamps.adresseIdentiquePourProgrammeCandidats === "Oui" ? demarcheValeursChamps.adresseDeRattachementComplement2Adresse : demarcheValeursChamps.adresseDeContactComplement2Adresse,
		idPieceJustificative: demarcheValeursChamps.idJustificatifIdentite,
		idPieceJustificative2: demarcheValeursChamps.idJustificatifIdentite2,
		idJustificatifDomicile: demarcheValeursChamps.idJustificatifDomicile,
		idJustificatifDomicile2: demarcheValeursChamps.idJustificatifDomicile2,
		idDeclarationEcrite: demarcheValeursChamps.idDeclarationEcrite,
		adresseEmail: formatterAdresseMail(demarcheValeursChamps.adresseEmail),
		numeroTelephone: formatterNumeroTelephone(demarcheValeursChamps.numeroTelephone),
		changementCommune : demarcheValeursChamps.changementCommune
	}
}

export function formatterNumeroTelephone(numeroTelephone: string) : string {
	return numeroTelephone.trim().split(/-| /).join("")
}

export function formatterAdresseMail(adresseMail: string) : string {
	return adresseMail.toLowerCase()
}

export default convertirVersDemarchePayload

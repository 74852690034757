import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

export default class NomDeFamilleValidation implements Validation {
  protected labelDuChamp = "Pays"
	protected PAYS_REGEX = /^[- a-zœæ'àâäçéèêëîïôöùûüÿ]+$/i
	protected LONGEUR_MAXI = 50
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
  	return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
  	if (valeur.trim().length === 0) {
  		return MessagesErreursValidation.PAYS_NON_RENSEIGNE
  	}
  	if (valeur.length > this.LONGEUR_MAXI) {
  		return MessagesErreursValidation.PAYS_TROP_LONG
  	}
  	if (!this.PAYS_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.PAYS_AVEC_CHIFFRE
			}
  		return MessagesErreursValidation.PAYS_AVEC_CARACTERE_SPECIAL
  	}
  	return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

import React, { useEffect } from "react"
import { MESSAGE_INDISPONIBILITE, TITRE_INDISPONIBILITE } from "../../../services/configurationHttp" // eslint-disable-line @typescript-eslint/no-unused-vars


const DemarcheIndisponible: React.FunctionComponent = () => {
	useEffect(() => {
		document.title = "Démarche d’inscription sur les listes électorales indisponible - service-public.fr"
	}, [])

	return (

		<div className="fr-pb-4w">
			<h2
				className="sp-demarche-step-titre">
				<span>
					<span
						className="sp-demarche-step-titre-nombre">
						<span
							aria-hidden="true"
							className="ri-arrow-left-right-line"
						>
						</span>
					</span>
					<span
						className="sp-demarche-step-titre-label"
						id="titreIndisponibilite"
					>
						{ TITRE_INDISPONIBILITE }
					</span>
				</span>
			</h2>
			<div
				className="fr-callout fr-mb-3w sp-callout-recap">
				<span
					aria-hidden="true"
					className="ri-arrow-left-right-line">
				</span>
				<div
					id="message"
					// eslint-disable-next-line
					dangerouslySetInnerHTML={{ __html: MESSAGE_INDISPONIBILITE }}>
				</div>
			</div>
		</div>
	)
}

export default DemarcheIndisponible

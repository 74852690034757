import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const PRENOM_REGEX = /^[- a-zœæ'àâäçéèêëîïôöùûüÿ]+$/i

export default class PrenomValidation implements Validation {
	protected labelDuChamp = "prénom"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "50"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.trim().length === 0) {
			return MessagesErreursValidation.PRENOM_OBLIGATOIRE
		}
		if (valeur.length > 50) {
			return MessagesErreursValidation.PRENOM_TROP_LONG
		}
		if (!PRENOM_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.PRENOM_AVEC_CHIFFRE
			}
			return MessagesErreursValidation.PRENOM_AVEC_CARACTERE_SPECIAL
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

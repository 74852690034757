import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const DATE_REGEX = /^\d{2}[/]\d{2}[/]\d{4}$/

export default class DateDeNaissanceCompleteValidation implements Validation {
	protected labelDuChamp = "dateDeNaissance"
	readonly attributsDeValidation = {
		"aria-required": true
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if ((valeur.trim().length === 0)) {
			return MessagesErreursValidation.DATE_DE_NAISSANCE_COMPLETE_OBLIGATOIRE
		}
		if ((!DATE_REGEX.test(valeur))) {
			return MessagesErreursValidation.DATE_DE_NAISSANCE_COMPLETE_MAUVAIS_FORMAT
		}
		if (!dateExiste(valeur)) {
			return MessagesErreursValidation.DATE_INVALIDE
		}
		if (calculerAge(valeur) < 18) {
			return MessagesErreursValidation.AGE_MOINS_DE_18_ANS
		}
		if (calculerAge(valeur) >= 125) {
			return MessagesErreursValidation.AGE_PLUS_DE_125_ANS
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

function calculerAge(dateDeNaissance: string): number {
	const dateDuJour = new Date()
	const dateNaissance = new Date(mettreDateAuFormatFrançaisPourParser(dateDeNaissance))
	let age = dateDuJour.getFullYear() - dateNaissance.getFullYear()
	const m = dateDuJour.getMonth() - dateNaissance.getMonth()
	if (m < 0 || (m === 0 && dateDuJour.getDate() < dateNaissance.getDate())) {
		age--
	}
	return age
}

function mettreDateAuFormatFrançaisPourParser(dateDeNaissance: string): string {
	return dateDeNaissance.split("/").reverse().join("/")
}

function dateExiste(dateDeNaissance: string): boolean {
	// On transforme jourActuel en Nombre puis en String dans le cas du 01/MM/YYYY par exemple
	const jourActuel = Number(dateDeNaissance.split("/")[0]).toString()
	const jourCalculeParJS = new Date(mettreDateAuFormatFrançaisPourParser(dateDeNaissance)).getDate().toString()
	if (jourActuel === jourCalculeParJS) {
		return true
	}
	return false
}

import React, { ReactNode } from "react"

type MiseEnAvantProps = {
	hidden?: boolean
	couleur?: "bleu" | "clear" | ""
	icone?: "info" | ""
	children: ReactNode,
}

const MiseEnAvant: React.FunctionComponent<MiseEnAvantProps> = ({ hidden = false, couleur = "", icone = "", children }: MiseEnAvantProps) => {
	const classNameCouleur: string = (couleur === "bleu" ? "sp-callout sp-callout--info" : "") || (couleur === "clear" ? "sp-callout sp-callout--clear" : "") 
	const classNameIcone: string = icone === "info" ? "ri-chat-info-line" : ""
	return (
		<div
			className={ "fr-callout fr-margin-bottom-2N " + classNameCouleur }
			hidden={ hidden }
		>
			<span className={ classNameIcone }></span>
			<p className="fr-text">
				{ children }
			</p>
		</div>
	)
}

export default MiseEnAvant

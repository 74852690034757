import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

type ChoixTypePj = {
	label: string
	valeur: string
}

export const listeChoixTypeJustificatif: ChoixTypePj[] = [
	{ label:"Carte nationale d'identité", valeur:"identite" },
	{ label:"Passeport", valeur:"passeport" },
	{ label:"Autres", valeur:"autres" }
]

export default class ChoixTypeJustificatifValidation implements Validation {
	readonly attributsDeValidation: ValidationAttributes = {}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur === "") {
			return MessagesErreursValidation.CHOIX_DU_TYPE_JUSTIFICATIF_NON_RENSEIGNE
		}
		const valeursPossibles = listeChoixTypeJustificatif.map(choix => choix.valeur)
		if (!valeursPossibles.includes(valeur)) {
			return MessagesErreursValidation.CHOIX_DU_TYPE_JUSTIFICATIF_NON_RENSEIGNE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}

}

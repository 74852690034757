import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"
import GestionnaireDeDemarche from "./GestionnaireDeDemarche"
import ChampsDuFormulaireDemarche from "./types/ChampsDuFormulaireDemarche"
import DemarcheHttp from "./types/DemarcheHttp"
import convertirVersDemarchePayload from "./ConvertirVersUneDemarchePayload"
import informationsTeledossierHTTP from "./types/InformationsTeledossierHttp"
import { format } from "date-fns"
import frenchLocale from "date-fns/locale/fr"
import { DonnéesRécapitulatif } from "./types/DonnéesRécapitulatif"
import { listePays } from "../ValidateurDeDemarche/validateursParChamp/NationaliteValidation"
import { extraireNumeroDepartementEntreParentheses } from "../MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentielAxios"

const GestionnaireAxiosDeDemarche = (): GestionnaireDeDemarche => {
	return { envoyer, générerRécapitulatif }
}

const messageErreurEnvoi = "La démarche n'a pas pu être envoyée."

const envoyer = (idUsager: string, champsDuFormulaireDemarche: ChampsDuFormulaireDemarche, accessToken: string): Promise<informationsTeledossierHTTP> => {
	const demarcheCouranteHttpÀEnvoyer: DemarcheHttp = convertirVersDemarchePayload(champsDuFormulaireDemarche)
	return axios.post(`${BACKEND_URL}/demande/${idUsager}`, demarcheCouranteHttpÀEnvoyer, {
		headers: { "Content-Type": "application/json", authorization: `Bearer ${accessToken}` }
	}).catch(() => {
		throw new Error(messageErreurEnvoi)
	})
}

const générerRécapitulatif = (valeurs: ChampsDuFormulaireDemarche): DonnéesRécapitulatif => {
	const dateDeNaissance = valeurs.usagerAUneDateDeNaissanceIncomplète ?
		formatterDateDeNaissanceIncomplète(valeurs.dateDeNaissanceIncomplète)
		: formatterDateDeNaissanceComplète(valeurs.dateDeNaissance)

	const prefixeDateDeNaissance = valeurs.usagerAUneDateDeNaissanceIncomplète ? "en" : "le"

	const paysChoisi = listePays.find((pays) => {
		return pays.valeur === valeurs.nationalite
	})

	const nationalité = paysChoisi ? paysChoisi.nationalité : "non connue"

	const adresseDeContact = () => {
		if(valeurs.adresseIdentiquePourProgrammeCandidats === "Oui") {
			return valeurs.adresseDeRattachementComplement2Adresse +
				" " + valeurs.adresseDeRattachementComplement1Adresse +
				" " + valeurs.adresseDeRattachementNumero +
				" " + valeurs.adresseDeRattachementLibelleDeVoie +
				" " + valeurs.adresseDeRattachementLieuDit +
				" " + valeurs.adresseDeRattachementCodePostal +
				" " + valeurs.adresseDeRattachementCommune
		}
		else {
			return valeurs.adresseDeContactComplement2Adresse +
				" " + valeurs.adresseDeContactComplement1Adresse +
				" " + valeurs.adresseDeContactNumero +
				" " + valeurs.adresseDeContactLibelleDeVoie +
				" " + valeurs.adresseDeContactLieuDit +
				" " + valeurs.adresseDeContactCodePostal +
				" " + valeurs.adresseDeContactCommune
				+ ((valeurs.adresseDeContactPays === "FRANCE") ? "" : (" " + valeurs.adresseDeContactPays))
		}
	}

	return {
		sexe: valeurs.sexe,
		prenomsEtNomDUsage: [valeurs.prenom, valeurs.prenom2 || "", valeurs.prenom3 || "", valeurs.nomDUsage].join(" ").replace(/  +/g, " ").trim(),
		prenoms: [valeurs.prenom, valeurs.prenom2 || "", valeurs.prenom3 || ""].join(" ").replace(/  +/g, " ").trim(),
		nomDeFamille: valeurs.nomDeFamille,
		nomDUsage: valeurs.nomDUsage,
		prefixeDateDeNaissance,
		dateDeNaissance,
		communeDeNaissanceAvecDepartement: `${valeurs.communeDeNaissance}${extraireNumeroDepartementEntreParentheses(valeurs.codeInseeCommuneDeNaissance)}`,
		paysDeNaissance: valeurs.paysDeNaissance,
		nationalité,
		nomJustificatifIdentite: valeurs.nomJustificatifIdentite,
		nomJustificatifIdentite2: !valeurs.unSeulFichierPJ ? valeurs.nomJustificatifIdentite2 : "",
		lieuDInscriptionAvecDepartement: `${valeurs.lieuDInscription}${extraireNumeroDepartementEntreParentheses(valeurs.codeInseeLieuDInscription)}`,
		adresse: `${valeurs.adresseDeRattachementComplement2Adresse} ${valeurs.adresseDeRattachementComplement1Adresse} ${valeurs.adresseDeRattachementNumero} ${valeurs.adresseDeRattachementLibelleDeVoie} ${valeurs.adresseDeRattachementLieuDit} ${valeurs.adresseDeRattachementCodePostal} ${valeurs.adresseDeRattachementCommune}`,
		nomJustificatifDomicile: valeurs.nomJustificatifDomicile,
		nomJustificatifDomicile2: !valeurs.unSeulFichierPJDomicile ? valeurs.nomJustificatifDomicile2 : "",
		nomDeclarationEcrite: valeurs.nomDeclarationEcrite,
		adresseDeContact: adresseDeContact(),
		adresseEmail: valeurs.adresseEmail,
		numeroTelephone: valeurs.numeroTelephone
	}
}

const formatterDateDeNaissanceIncomplète = (dateDeNaissanceIncomplète: string|undefined): string => {
	if (dateDeNaissanceIncomplète && dateDeNaissanceIncomplète.includes("/")) {
		const [mois, année] = dateDeNaissanceIncomplète.split("/")
		return format(new Date(parseInt(année), parseInt(mois) - 1), "MMMM yyyy", { locale: frenchLocale })
	}
	else if (dateDeNaissanceIncomplète) {
		return format(new Date(dateDeNaissanceIncomplète), "yyyy", { locale: frenchLocale })
	}
	return ""
}

const formatterDateDeNaissanceComplète = (dateDeNaissanceComplète: string|undefined): string => {
	if(dateDeNaissanceComplète) {
		const [jour, mois, année] = dateDeNaissanceComplète.split("/")
		return format(new Date(parseInt(année), parseInt(mois) - 1, parseInt(jour)), "d MMMM yyyy", { locale: frenchLocale })
	}
	return ""
}

export default GestionnaireAxiosDeDemarche

import axios from "axios"
import { DILE_AUTH_URL } from "../configurationHttp"

const wrapper = axios.create({ withCredentials: true })
wrapper.interceptors.response.use( 
	response => response, 
	error => {
		if ( isAuthenticationError(error) ) {
			window.location.assign(DILE_AUTH_URL)
		}
		return Promise.reject(error)
	}
)

function isAuthenticationError(error: any) {
	return error?.response?.status === 401 || error?.response?.status === 403
}

export default wrapper

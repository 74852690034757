import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation from "./Validation"

const CODE_POSTAL_ETRANGER_REGEX = /^[ a-z0-9\u2010\u002D\u2013\u2014\u2012]+$/i

export default class CodePostalDeResidenceEtrangerValidation implements Validation {
	protected labelDuChamp = "code postal"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "20"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if ((valeur !== undefined) && (valeur.trim().length > 0)) {
			if (valeur.trim().length > 20) {
				return MessagesErreursValidation.CODE_POSTAL_NON_VALIDE_ETRANGER
			}
			if (!CODE_POSTAL_ETRANGER_REGEX.test(valeur)) {
				return MessagesErreursValidation.CODE_POSTAL_CARACTERE_INATTENDU_ETRANGER
			}
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


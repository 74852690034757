import ChampAutoCompletion, { Suggestion } from "../../ui/ChampAutoCompletion/ChampAutoCompletion"
import React, { useContext } from "react"
import { MoteurDeRechercheDeDonneesReferentielContext } from "../../App"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"
import { extraireNumeroDepartementEntreParentheses } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/MoteurDeRechercheDeDonneesReferentielAxios"
import { LieuDeNaissance } from "../../../services/MoteurDeRechercheDeDonneesReferentiels/types/LieuDeNaissanceReponseHttp"

interface ChampReferentielCommuneDeNaissanceProps {
	communeDeNaissance: string
	codeInseeCommuneDeNaissance: string
	onChange: (suggestion: LieuDeNaissance) => void
	validation: Validation
	messageErreurFormulaire?: string
}

const ChampReferentielCommuneDeNaissance: React.FunctionComponent<ChampReferentielCommuneDeNaissanceProps> = ({ communeDeNaissance, codeInseeCommuneDeNaissance, onChange, validation, messageErreurFormulaire }: ChampReferentielCommuneDeNaissanceProps) => {
	const moteurDeRechercheDeDonneesReferentiel = useContext(MoteurDeRechercheDeDonneesReferentielContext)
	const suggérerCommuneDeNaissance: (terme: string) => Promise<Suggestion<LieuDeNaissance>[]> = (terme: string) => {
		return moteurDeRechercheDeDonneesReferentiel.rechercherLieuxDeNaissance(terme)
	}

	const mettreAJourValeur = (lieuDeNaissance: LieuDeNaissance) => {
		onChange(lieuDeNaissance)
	}

	const suggestionCommuneDeNaissanceVide = { key: "", label:"", values: { code: "", libelle: "" } }
	return (
		<ChampAutoCompletion
			compléter={ suggérerCommuneDeNaissance }
			id={ "communeDeNaissance" }
			label={ "Commune de naissance" }
			messageErreurFormulaire={ messageErreurFormulaire }
			name={ "communeDeNaissance" }
			onSelect={ mettreAJourValeur }
			suggestionReinitialisante={ suggestionCommuneDeNaissanceVide.values }
			texteAide="Exemple : Sainte-Cécile-Les-Vignes (84)"
			valeurInitiale={ communeDeNaissance + extraireNumeroDepartementEntreParentheses(codeInseeCommuneDeNaissance) }
			validation={ validation }
		/>
	)
}

export default ChampReferentielCommuneDeNaissance

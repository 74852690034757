import React, { Fragment, useState } from "react"
import ChampAdresseRedressee from "../ChampAdresseRedressee/ChampAdresseRedressee"
import AdresseRedresseeValidation	from "../../../services/ValidateurDeDemarche/validateursParChamp/AdresseRedresseeValidation"
import CaseACocher from "../../ui/CaseACocher/CaseACocher"
import ChampTextuel from "../../ui/ChampTextuel/ChampTextuel"
import CommuneDeResidenceEtrangerValidation	from "../../../services/ValidateurDeDemarche/validateursParChamp/CommuneDeResidenceEtrangerValidation"
import CommuneDeResidenceValidation	from "../../../services/ValidateurDeDemarche/validateursParChamp/CommuneDeResidenceValidation"
import CodePostalDeResidenceValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/CodePostalDeResidenceValidation"
import CodePostalDeResidenceEtrangerValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/CodePostalDeResidenceEtrangerValidation"
import NumeroDeVoieValidation from "../../../services/ValidateurDeDemarche/validateursParChamp/NumeroDeVoieValidation"
import ChampComplementAdresse from "../ChampComplementAdresse/ChampComplementAdresse"
import ChampsDuFormulaireAdresse from "../../../services/GestionnaireDeDemarche/types/ChampsDuFormulaireAdresse"
import LieuDitLibelleDeVoieValidation	from "../../../services/ValidateurDeDemarche/validateursParChamp/LieuDitLibelleDeVoieValidation"

type FormulaireAdresseProps = {
	adresse: ChampsDuFormulaireAdresse,
	afficherRNVP: boolean,
	mettreAJourLeChamp: (event: React.ChangeEvent<HTMLInputElement>) => void,
	setErreursValeursAdresse: (arg: Partial<ChampsDuFormulaireAdresse>) => void,
	setValeursAdresse: (arg: ChampsDuFormulaireAdresse) => void,
	typeAdresse: "adresseDeRattachement" | "adresseDeContact",
	filtreCommune: string,
	filtreNumeroDepartement: string,
	messageErreurFormulaire?: Partial<ChampsDuFormulaireAdresse>
	viderChamp?: boolean,
}

const FormulaireAdresse: React.FunctionComponent<FormulaireAdresseProps> = ({ adresse, setValeursAdresse, mettreAJourLeChamp, setErreursValeursAdresse,	typeAdresse, filtreCommune, filtreNumeroDepartement, messageErreurFormulaire, viderChamp, afficherRNVP	}: FormulaireAdresseProps) => {
	const [messageChangementDeContexteBlocAdresse, setMessageChangementDeContexteBlocAdresse] = useState<string>("")
	const suffixe = typeAdresse.charAt(0).toUpperCase() + typeAdresse.substring(1)
	const texteAideCaseACocherQuandLaCaseEstDecochee = "Cochez si votre adresse n'est pas proposée"
	const texteAideCaseACocherQuandLaCaseEstCochee = "Décochez pour utiliser la recherche d’adresse au-dessus"
	const [texteAideCaseACocher, setTexteAideCaseACocher] = useState<string>(texteAideCaseACocherQuandLaCaseEstDecochee)

	const donnerTexteAideLibelleVoie = ( lieuDit: string, enFranceOuALEtranger: string ) => {
		if (enFranceOuALEtranger === "FRANCE") {
			return lieuDit === "" ? "Exemple : Rue Louise Michel" : "Facultatif - Exemple : Rue Louise Michel"
		}
		return lieuDit === "" ? "Exemple : Postdamer Straße" : "Facultatif - Exemple : Postdamer Straße"
	}
	const donnerTexteAideLieuDit= ( libelleDeVoie: string, enFranceOuALEtranger: string ) => {
		if (enFranceOuALEtranger === "FRANCE") {
			return libelleDeVoie === "" ? "Exemple : Ferme de la Gautrais" : "Facultatif - Exemple : Ferme de la Gautrais"
		}
		return libelleDeVoie === "" ? "Exemple : Burg Island" : "Facultatif - Exemple : Burg Island"
	}

	const auClickSurCaseACocherAdresse = ( event: React.ChangeEvent<HTMLInputElement> ) => {
		const laCaseEstCochée = event.target.checked
		setValeursAdresse({
			...adresse,
			usagerRemplitSonAdresseManuellement: laCaseEstCochée,
			adresseRedressee: "",
			libelleDeVoie: "",
			numero: "",
			commune: "",
			codePostal: "",
			lieuDit: "",
			complement1Adresse: "",
			complement2Adresse: "",
		})
		setTexteAvertissementMajAdresseRedressee(
			laCaseEstCochée ? "Le champ Adresse est maintenant inactif" : "Le champ Adresse est maintenant actif"
		)
		setTexteAideCaseACocher(
			laCaseEstCochée ?
				texteAideCaseACocherQuandLaCaseEstCochee : texteAideCaseACocherQuandLaCaseEstDecochee )
	}

	const mettreAJourLeChampLibelleDeVoie = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessageChangementDeContexteBlocAdresse("")
		mettreAJourLeChamp(event)
		const validationLibelleDeVoieLieuDit = new LieuDitLibelleDeVoieValidation()
		const erreursDeValidation = validationLibelleDeVoieLieuDit.messageErreur([event.target.value, adresse.lieuDit, adresse.pays])
		setErreursValeursAdresse( {
			libelleDeVoie: erreursDeValidation?.libelleDeVoie? erreursDeValidation.libelleDeVoie : "",
			lieuDit: erreursDeValidation?.lieuDit? erreursDeValidation.lieuDit : "",
		} )
		if (event.target.value!==""){
			setMessageChangementDeContexteBlocAdresse("Le champ Lieu-dit ou commune déléguée ou boîte postale est maintenant optionnel.")
		}
	}

	const mettreAJourLeChampLieuDit = (event: React.ChangeEvent<HTMLInputElement>) => {
		mettreAJourLeChamp(event)
		const validationLibelleDeVoieLieuDit = new LieuDitLibelleDeVoieValidation()
		const erreursDeValidation = validationLibelleDeVoieLieuDit.messageErreur([adresse.libelleDeVoie, event.target.value, adresse.pays])
		setErreursValeursAdresse( {
			libelleDeVoie: erreursDeValidation?.libelleDeVoie? erreursDeValidation.libelleDeVoie : "",
			lieuDit: erreursDeValidation?.lieuDit? erreursDeValidation.lieuDit : "",
		} )
		if (adresse.libelleDeVoie===""){
			setMessageChangementDeContexteBlocAdresse("Le champ Nom de la voie est maintenant optionnel.")
		}
	}

	const [texteAvertissementMajAdresseRedressee, setTexteAvertissementMajAdresseRedressee] = useState<string>("" )

	const codePostalRequiredProp = adresse.pays === "FRANCE" ? {
		"required" : true
	} : ""

	const champsCommuneAvecAutocomplete = <ChampTextuel
		autoComplete={ "address-level2" }
		id={ typeAdresse + "Commune" }
		label="Commune"
		maxLength={ 50 }
		messageErreurFormulaire={ messageErreurFormulaire?.commune }
		name={ typeAdresse + "Commune" }
		onChange={ mettreAJourLeChamp }
		required={ true }
		texteAide="Exemple : Saint-Jacques-de-la-Lande"
		valeur={ adresse.commune }
		validation={ new CommuneDeResidenceValidation() }
	/>
	const champsCommuneSansAutocomplete = <ChampTextuel
		id={ typeAdresse + "Commune" }
		label="Commune"
		maxLength={ 50 }
		messageErreurFormulaire={ messageErreurFormulaire?.commune }
		name={ typeAdresse + "Commune" }
		onChange={ mettreAJourLeChamp }
		required={ true }
		texteAide={ adresse.pays === "FRANCE" ? "Exemple : Saint-Jacques-de-la-Lande" : "Exemple : Berlin" }
		valeur={ adresse.commune }
		validation={ adresse.pays === "FRANCE" ? new CommuneDeResidenceValidation() : new CommuneDeResidenceEtrangerValidation() }
	/>
	const champsCodePostalAvecAutocomplete = <ChampTextuel
		autoComplete={ "postal-code" }
		id={ typeAdresse + "CodePostal" }
		label={ "Code postal" }
		maxLength={ 5 }
		messageErreurFormulaire={ messageErreurFormulaire?.codePostal }
		name={ typeAdresse + "CodePostal" }
		onChange={ mettreAJourLeChamp }
		{ ...codePostalRequiredProp }
		texteAide="Exemple : 35136"
		valeur={ adresse.codePostal }
		validation={ new CodePostalDeResidenceValidation() }
	/>
	const champsCodePostalSansAutocomplete = <ChampTextuel
		id={ typeAdresse + "CodePostal" }
		label={ "Code postal" }
		maxLength={ adresse.pays === "FRANCE" ? 5 : 20 }
		messageErreurFormulaire={ messageErreurFormulaire?.codePostal }
		name={ typeAdresse + "CodePostal" }
		onChange={ mettreAJourLeChamp }
		{ ...codePostalRequiredProp }
		texteAide={ adresse.pays === "FRANCE" ? "Exemple : 35136" : "Facultatif - Exemple : STHL 1ZZ" }
		valeur={ adresse.codePostal }
		validation={ adresse.pays === "FRANCE" ? new CodePostalDeResidenceValidation() : new CodePostalDeResidenceEtrangerValidation() }
	/>

	return (
		<Fragment>
			{ afficherRNVP &&
		<Fragment>
			<ChampAdresseRedressee
				adresseRedressee={ adresse.adresseRedressee }
				codePostal={ adresse.codePostal }
				commune={ adresse.commune }
				disabled={ adresse.usagerRemplitSonAdresseManuellement }
				filtreCommune={ filtreCommune }
				filtreNumeroDepartement={ filtreNumeroDepartement }
				id={ typeAdresse + "AdresseRedressee" }
				label="Adresse"
				libelleDeVoie={ adresse.libelleDeVoie }
				messageErreurFormulaire={ messageErreurFormulaire?.adresseRedressee }
				name={ typeAdresse + "AdresseRedressee" }
				numero={ adresse.numero }
				onChange={ (suggestion) => setValeursAdresse({
					...adresse,
					adresseRedressee: suggestion.numéroDeVoie + " " + suggestion.nomDeVoie + " " + suggestion.codePostal + " " + suggestion.ville,
					codePostal: suggestion.codePostal,
					commune: suggestion.ville,
					numero: suggestion.numéroDeVoie,
					libelleDeVoie: suggestion.nomDeVoie
				}) }
				validation={ new AdresseRedresseeValidation() }
				viderChamp={ viderChamp }
			/>
			<CaseACocher
				caseEstCochée={ adresse.usagerRemplitSonAdresseManuellement }
				id={ "no-adresse-rnvp" + suffixe }
				label="Écrire mon adresse"
				onChange={ auClickSurCaseACocherAdresse }
				texteAide={ texteAideCaseACocher }
				texteAvertissementDeLaMiseAJour= { texteAvertissementMajAdresseRedressee }
				texteAvertissementPréalable="Saisie complémentaire requise - affichage automatique"
				valeur="no-adresse-rnvp"
			/>
		</Fragment>
			}
			{ (!afficherRNVP || adresse.usagerRemplitSonAdresseManuellement) &&
	<Fragment>
		{ adresse.autocompleteAdresse ? champsCommuneAvecAutocomplete : champsCommuneSansAutocomplete }
		{ adresse.autocompleteAdresse ? champsCodePostalAvecAutocomplete : champsCodePostalSansAutocomplete }
		<p
			aria-atomic="true"
			aria-live="assertive"
			className="fr-sr-only"
		>
			{ messageChangementDeContexteBlocAdresse }
		</p>
		<div className="fr-form-group fr-mt-2w">
			<fieldset
				className="fr-fieldset"
			>
				<legend className="fr-fieldset__legend fr-text--regular">
					Indiquez au moins un nom de voie ou un lieu-dit
				</legend>
				<ChampTextuel
					id={ typeAdresse + "Numero" }
					label={ "Numéro" }
					maxLength={ 20 }
					messageErreurFormulaire={ messageErreurFormulaire?.numero }
					name={ typeAdresse + "Numero" }
					onChange={ mettreAJourLeChamp }
					texteAide={ adresse.pays === "FRANCE" ? "Facultatif - Exemple : 42 bis" : "Facultatif - Exemple : 33" }
					valeur={ adresse.numero }
					validation={ new NumeroDeVoieValidation() }
				/>
				<ChampTextuel
					id={ typeAdresse + "LibelleDeVoie" }
					label={ "Nom de la voie" }
					maxLength={ 150 }
					messageErreurFormulaire={ messageErreurFormulaire?.libelleDeVoie }
					name={ typeAdresse + "LibelleDeVoie" }
					onChange={ mettreAJourLeChampLibelleDeVoie }
					required={ adresse.libelleDeVoie !== "" || adresse.lieuDit === "" }
					texteAide={ donnerTexteAideLibelleVoie(adresse.lieuDit, adresse.pays)  }
					valeur={ adresse.libelleDeVoie }
				/>
				<ChampTextuel
					id={ typeAdresse + "LieuDit" }
					label={ "Lieu-dit ou commune déléguée ou boîte postale" }
					maxLength={ 50 }
					messageErreurFormulaire={ messageErreurFormulaire?.lieuDit }
					name={ typeAdresse + "LieuDit" }
					onChange={ mettreAJourLeChampLieuDit }
					required={ adresse.libelleDeVoie === "" || adresse.lieuDit !== "" }
					texteAide={ donnerTexteAideLieuDit(adresse.libelleDeVoie, adresse.pays) }
					valeur={ adresse.lieuDit }
				/>
			</fieldset>
		</div>
	</Fragment>
			}
			<ChampComplementAdresse
				complement1Adresse={ adresse.complement1Adresse }
				complement2Adresse={ adresse.complement2Adresse }
				messageErreurFormulaireComplement1Adresse={ messageErreurFormulaire?.complement1Adresse }
				messageErreurFormulaireComplement2Adresse={ messageErreurFormulaire?.complement2Adresse }
				onChange={ mettreAJourLeChamp }
				pays={ adresse.pays }
				suffixe={ typeAdresse }
			/>
		</Fragment>
	)
}

export default FormulaireAdresse


import ValidationMultiChamps, { ValidationMultiChampsAttributes } from "./ValidationMultiChamps"
import LieuDitValidation from "./LieuDitValidation"
import LibelleDeVoieValidation from "./LibelleDeVoieValidation"
import MessagesErreursValidation from "./MessagesErreursValidation"

type erreurLieuDitLibelleDeVoieValidation = { libelleDeVoie?: string, lieuDit?: string}

export default class LieuDitLibelleDeVoieValidation implements ValidationMultiChamps {
	readonly attributsDeValidation: ValidationMultiChampsAttributes = {
	}

	estValide([libelleDeVoie, lieuDit, pays]: string[]): boolean {
		return this.messageErreur([libelleDeVoie, lieuDit, pays]).libelleDeVoie === "" && this.messageErreur([libelleDeVoie, lieuDit, pays]).lieuDit === ""
	}

	messageErreur([libelleDeVoie, lieuDit, pays]: string[]): erreurLieuDitLibelleDeVoieValidation {
		const validationLieuDit = new LieuDitValidation()
		const validationLibelleDeVoie = new LibelleDeVoieValidation()
		let messageErreurLibelleDeVoie = ""
		let messageErreurLieuDit = ""
		if (libelleDeVoie === "" && lieuDit === ""){
			messageErreurLibelleDeVoie = ((pays === "FRANCE") ? MessagesErreursValidation.LIBELLE_DE_VOIE_NON_RENSEIGNE : MessagesErreursValidation.LIBELLE_DE_VOIE_NON_RENSEIGNE_ETRANGER)
			messageErreurLieuDit = ((pays === "FRANCE") ? MessagesErreursValidation.LIEU_DIT_NON_RENSEIGNE : MessagesErreursValidation.LIEU_DIT_NON_RENSEIGNE_ETRANGER)
		}
		if (libelleDeVoie === "" && lieuDit !== "") {
			messageErreurLieuDit = validationLieuDit.messageErreur(lieuDit)
		}
		if (libelleDeVoie !== "" && lieuDit === "") {
			messageErreurLibelleDeVoie = validationLibelleDeVoie.messageErreur(libelleDeVoie)
		}
		if (libelleDeVoie !== "" && lieuDit !== "") {
			messageErreurLibelleDeVoie = validationLibelleDeVoie.messageErreur(libelleDeVoie)
			messageErreurLieuDit = validationLieuDit.messageErreur(lieuDit)
		}
		return { libelleDeVoie : messageErreurLibelleDeVoie, lieuDit: messageErreurLieuDit }
	}
}

import React, { Fragment } from "react"
import styles from "./PieceJustificative.module.css"
import classNames from "classnames"

const TexteAideJustificatifDomicile : React.FunctionComponent = () => {
	return (
		<Fragment>
			<h4 className="fr-text">Quel justificatif fournir ?</h4>
			<div>
				<p>Les justificatifs doivent <strong>prouver selon votre situation :</strong></p>
				<ul className={ classNames(styles.pieceJustificativeTexteAideBulletPoint, "is-list fr-mb-2w") }>
					<li><span>Soit votre <strong>domicile</strong> ou votre <strong>résidence</strong> sur la commune</span></li>
					<li><span>Soit la <strong>résidence de vos parents</strong> et <strong>votre lien filial</strong> avec ceux-ci</span></li>
					<li><span>Soit votre qualité de <strong>contribuable</strong> sur la commune</span></li>
					<li><span>Soit votre qualité de <strong>gérant</strong> ou d’<strong>associé majoritaire ou unique</strong> d’une <strong>société</strong> sur la commune</span>
					</li>
				</ul>
				<p>
					La liste de <strong>tous les justificatifs recevables</strong> pour cette démarche est <strong>disponible sur service-public.fr</strong>
				</p>
			</div>
		</Fragment>
	)
}

export default TexteAideJustificatifDomicile

import React, { AriaAttributes, ChangeEvent, ReactNode, SyntheticEvent, useEffect, useRef, useState } from "react"
import Autosuggest from "react-autosuggest"
import useSuggestionsHook from "./useSuggestions"
import "./ChampAutoCompletion.css"
import classNames from "classnames"
import ReactDOM from "react-dom"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"

export interface Suggestion<T> {
	key: string,
	label: string,
	values: T
}

interface Props<T> {
	id: string,
	name: string,
	label: string,
	texteAide?: string,
	compléter: (terme: string) => Promise<Suggestion<T>[]>,
	messageErreurFormulaire?: string,
	disabled?: boolean,
	validation: Validation,
	onSelect: (valeursSelectionnées: T) => void
	valeurInitiale: string
	viderChamp?: boolean,
	suggestionReinitialisante?: T
}

const ChampAutoCompletion: React.FunctionComponent<Props<any>> = ({ id, name, label, texteAide = "", compléter, messageErreurFormulaire, disabled, validation, onSelect, valeurInitiale, suggestionReinitialisante, viderChamp } : Props<any>) => {
	const descriptionId = `description-${id}`
	const errorId = `error-${id}`
	const descriptionPourLecteurDEcranId = `description-sr-only-${id}`
	const [suggestions, suggérerPour, annulerSuggestions] = useSuggestionsHook(compléter)
	const [valeurCourante, setValeurCourante] = useState<string>("")
	const [dernierInputValueSelectionné, setDernierInputValueSelectionné] = useState<string>("")
	const suggestionsContainer = useRef(document.createElement("div"))
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)

	const [messageErreur, setMessageErreur] = useState<string | undefined>()
	const messageErreurAffiche = aEteModifie ? messageErreur : messageErreurFormulaire
	const valeurAffichée = aEteModifie ? valeurCourante : valeurInitiale

	useEffect(() => {
		if (disabled) {
			réinitialiser()
		}
	}, [disabled])

	useEffect(() => {
		if (viderChamp && valeurAffichée != "") {
			setAEteModifie(true)
			réinitialiser()
		}
	}, [viderChamp])

	function réinitialiser () {
		setMessageErreur("")
		setValeurCourante("")
	}

	const afficherMessagerErreur = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setMessageErreur(validation.messageErreur(""))
		event.target.setCustomValidity(validation.messageErreur(""))
	}

	const onBlur = (event: ChangeEvent<HTMLSelectElement>) => {
		event.preventDefault()
		setAEteModifie(true)
		setValeurCourante(event.target.value)
		const usagerAChangéLaValeurDepuisLaSelection = event.target.value !== dernierInputValueSelectionné
		const usagerAChangéLaValeurInitialeSansSelectionnerDeNouvellesValeurs = valeurInitiale !== "" && event.target.value !== valeurInitiale
		const ilNYAvaitPasDeValeursInitiales = valeurInitiale === ""

		if ((usagerAChangéLaValeurDepuisLaSelection &&
											(usagerAChangéLaValeurInitialeSansSelectionnerDeNouvellesValeurs || ilNYAvaitPasDeValeursInitiales))
											|| !validation.estValide(event.target.value)) {
			afficherMessagerErreur(event)
			onSelect(suggestionReinitialisante)
		} else {
			setMessageErreur(undefined)
			event.target.setCustomValidity("")
		}
	}

	const onInputChange = (value: string) => {
		setAEteModifie(true)
		setValeurCourante(value)
	}

	const onSuggestionSelected = (event: SyntheticEvent, data: any) => {
		const { suggestion } = data
		setDernierInputValueSelectionné(suggestion.label)
		onSelect(suggestion.values)
	}

	const srOnlyText = suggestions.length
		? `${suggestions.length} suggestions`
		: valeurCourante ? "Aucune suggestion" : ""

	const ariaAttributes: AriaAttributes = {
		"aria-autocomplete": "both",
		"aria-describedby": `${descriptionPourLecteurDEcranId} ${descriptionId} ${errorId}`,
		"aria-required": true
	}

	const classesInput = classNames("fr-input", {
		"fr-input--error": Boolean(messageErreurAffiche),
	})

	const inputProps = {
		id,
		name,
		type: "search",
		className: classesInput,
		value: valeurAffichée,
		autoCapitalize: "off",
		autoComplete: "chrome-off",
		spellCheck: false,
		onBlur: (e: any) => onBlur(e),
		onChange: (e: any, update: any) => onInputChange(update.newValue),
		...ariaAttributes,
		disabled,
	}

	const classesDuGroupe = classNames("fr-input-group champ-referentiel", {
		"fr-input-group--error": Boolean(messageErreurAffiche),
	})

	function getSuggestion(suggestion: Suggestion<any>) {
		return suggestion.label
	}

	const renderSuggestion = (suggestion: Suggestion<any> | any) => {
		const ul=document.querySelector(".react-autosuggest__container ul")
		if (ul) {
			ul.setAttribute("aria-label", label)
		}							
		return <span>{ suggestion.label }</span>
	}

	return (
		<div className={ classesDuGroupe }>
			<label
				className="fr-label"
				htmlFor={ id }
			>
				{ label }
			</label>
			<p
				className="fr-hint-text"
				id={ descriptionId }
			>
				{ texteAide }
			</p>
			<Autosuggest
				alwaysRenderSuggestions={ false }
				getSuggestionValue={ getSuggestion }
				highlightFirstSuggestion={ true }
				id={ id }
				inputProps={ inputProps }
				onSuggestionSelected={ onSuggestionSelected }
				onSuggestionsClearRequested={ () => annulerSuggestions() }
				onSuggestionsFetchRequested={ ({ value }) => {
					suggérerPour(value)
				} }
				renderInputComponent={ renderInputComponent }
				renderSuggestion={ renderSuggestion }
				renderSuggestionsContainer={ renderSuggestionsContainer }
				suggestions={ suggestions }
			/>
			<div
				className="react-autosuggest__container"
				ref={ suggestionsContainer }
			/>
			<p
				className="fr-error-text"
				hidden={ Boolean(!messageErreurAffiche) }
				id={ errorId }
				role="alert"
			>
				{ messageErreurAffiche }
			</p>
		</div>
	)

	function renderInputComponent(suggestionInputProps: any) {
		return (
			<React.Fragment>
				<SrOnly>
					<p id={ descriptionPourLecteurDEcranId }>
						Des suggestions de saisies vous seront proposées lors de votre saisie
					</p>
				</SrOnly>
				<SrOnly role="status">
					<p>{ srOnlyText }</p>
				</SrOnly>
				<input { ...suggestionInputProps } />
				<span
					aria-hidden={ true }
					className="icon-search fr-fi-search-line"
				/>
			</React.Fragment>
		)
	}

	function renderSuggestionsContainer({ containerProps, children }: any) {
		const { role, ...autresContainerProps } = containerProps // eslint-disable-line @typescript-eslint/no-unused-vars
		const suggestionsAafficher = (
			<div
				{ ...autresContainerProps }
				role="presentation"
			>
				{ children }
			</div>
		)

		return (
			ReactDOM.createPortal(suggestionsAafficher, suggestionsContainer.current)
		)
	}
}

interface SrOnlyProps {
	children: ReactNode,
	className?: string,
	role?: string
}

const SrOnly: React.FunctionComponent<SrOnlyProps> = ({ role, className = "", children, ...props }: SrOnlyProps) => {
	return (
		<div
			{ ...props }
			className={ "fr-sr-only" + className }
			role={ role }
		>
			{ children }
		</div>
	)
}

export default ChampAutoCompletion
export type ChampReferentielProps = Props<any>

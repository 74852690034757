import React from "react"

type ErreurAuthentificationProps = {
	description: string
}


const ErreurAuthentification: React.FunctionComponent<ErreurAuthentificationProps> = ( { description } : ErreurAuthentificationProps) => {
	return (
		<div id="root">
			<div
				className="App">
				<header
					className="fr-header"
					id="banner"
					role="banner">
					<div
						className="fr-container">
						<div
							className="fr-header__body">
							<div
								className="fr-container">
								<div
									className="fr-header__body-row">
									<div
										className="fr-header__brand fr-enlarge-link">
										<div
											className="fr-header__brand-top">
											<div
												className="fr-header__logo">
												<p
													className="fr-logo">République
													<br/>Française
												</p>
											</div>
										</div>
										<div
											className="fr-header__service">
											<a
												href="https://www.service-public.fr/"
												title="Accueil Service-Public.fr">
												<p
													className="fr-header__service-title">Service-Public.fr
												</p>
											</a>
											<p
												className="fr-header__service-tagline">Le site officiel de l’administration française
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div
					className="fr-container sp-bg-sm fr-mb-0 fr-pb-2w">
					<main
						className="main"
						id="main"
						role="main">
						<div
							className="fr-grid-row">
							<div
								className="fr-col-offset-md-1 fr-col-md-10 fr-col-offset-lg-3 fr-col-lg-6 fr-pt-2w">
								<h1
									className="sp-titre-demarche fr-mt-6w">Votre demande d’inscription sur les listes électorales
								</h1>
								<div
									className="fr-pb-4w">
									<svg
										aria-hidden="true"
										className="fr-mb-1w"
										height="54px"
										viewBox="0 0 54 54"
										width="54px">
										<path
											d="M26.9999 53.6666C12.2719 53.6666 0.333252 41.728 0.333252 27C0.333252 12.272 12.2719 0.333313 26.9999 0.333313C41.7279 0.333313 53.6666 12.272 53.6666 27C53.6666 41.728 41.7279 53.6666 26.9999 53.6666ZM26.9999 48.3333C32.6579 48.3333 38.0841 46.0857 42.0849 42.0849C46.0856 38.0842 48.3333 32.6579 48.3333 27C48.3333 21.342 46.0856 15.9158 42.0849 11.915C38.0841 7.91426 32.6579 5.66665 26.9999 5.66665C21.342 5.66665 15.9158 7.91426 11.915 11.915C7.9142 15.9158 5.66659 21.342 5.66659 27C5.66659 32.6579 7.9142 38.0842 11.915 42.0849C15.9158 46.0857 21.342 48.3333 26.9999 48.3333V48.3333ZM24.3333 35H29.6666V40.3333H24.3333V35ZM24.3333 13.6666H29.6666V29.6666H24.3333V13.6666Z"
											fill="#000091">
										</path>
										<path
											d="M24.3333 35H29.6667V40.3334H24.3333V35ZM24.3333 13.6667H29.6667V29.6667H24.3333V13.6667Z"
											fill="#E1000F">
										</path>
									</svg>
									<h1
										className="fr-h3 fr-mb-1w">La connexion au service en ligne est provisoirement indisponible

									</h1>
									<p
										className="sp-color-error fr-mb-3w fr-text--lg">
										{
											(description == "grant request is invalid" &&
												"Demande d'accès invalide")
											|| (description == "code not valid" &&
												"Code invalide")
											|| (description == "Network Error" &&
												"Erreur réseau")
											||  (description == "L'adresse internet est invalide" &&
												"L'adresse internet est invalide")
											|| (description == "Network Error" &&
												"Erreur réseau")
											||
											<span>Erreur technique - { description }</span>
										}
									</p>
									<p>Veuillez nous excuser pour ce désagrément. Nous faisons tout pour rétablir le service le plus
										rapidement possible.
									</p>
									<ul
										className="fr-raw-list fr-pt-2w">
										<li
											className="sp-text-align--right">
											<a
												className="fr-link fr-fi-arrow-right-line fr-link--icon-right"
												href="/">Essayer à nouveau
											</a>
										</li>
										<li
											className="sp-text-align--right">
											<a
												className="fr-link fr-fi-arrow-right-line fr-link--icon-right"
												href="https://www.service-public.fr/particuliers/vosdroits/R16396/signaler-un-probleme"
												rel="noopener noreferrer"
												target="_blank"
												title="Obtenir de l'aide - nouvelle fenêtre">Obtenir de l’aide
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</main>
				</div>
				<footer
					className="fr-footer"
					id="footer"
					role="contentinfo">
					<div
						className="fr-footer__top">
						<div
							className="fr-container">
							<div
								className="fr-grid-row fr-grid-row--start fr-grid-row--gutters"
								role="list">
								<div
									className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4"
									role="listitem">
									<div
										className="fr-footer__top-cat">Besoin d’aide pour ce service en ligne&nbsp;?
									</div>
									<ul
										className="fr-footer__top-list">
										<li>
											<a
												className="fr-footer__top-link"
												href="https://www.service-public.fr/particuliers/vosdroits/R16396/signaler-un-probleme"
												rel="noopener noreferrer"
												target="_blank"
												title="Avez-vous une question ou un problème ? - Nouvelle fenêtre">Avez-vous une question ou
												un
												problème&nbsp;?
											</a>
										</li>
										<li>
											<a
												className="fr-footer__top-link"
												href="https://www.service-public.fr/particuliers/vosdroits/N47#1"
												rel="noopener noreferrer"
												target="_blank"
												title="Toute l’information sur cette démarche - Nouvelle fenêtre">Toute l’information sur
												cette
												démarche
											</a>
										</li>
									</ul>
								</div>
								<div
									className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4"
									role="listitem">
									<div
										className="fr-footer__top-cat">Nos engagements
									</div>
									<ul
										className="fr-footer__top-list">
										<li>
											<a
												className="fr-footer__top-link"
												href="https://www.service-public.fr/P10125"
												rel="noopener noreferrer"
												target="_blank"
												title="Accessibilité de la démarche - Nouvelle fenêtre">Accessibilité de la
												démarche : partiellement conforme
											</a>
										</li>
										<li>
											<a
												className="fr-footer__top-link"
												href="https://www.service-public.fr/P10129"
												rel="noopener noreferrer"
												target="_blank"
												title="Données personnelles et sécurité de la démarche - Nouvelle fenêtre">Données
												personnelles et sécurité de la démarche
											</a>
										</li>
										<li>
											<a
												className="fr-footer__top-link"
												href="https://www.service-public.fr/P10128"
												rel="noopener noreferrer"
												target="_blank"
												title="Conditions générales d'utilisation de la démarche - Nouvelle fenêtre">Conditions
												générales d’utilisation de la démarche
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						className="fr-container">
						<div
							className="fr-footer__body">
							<div
								className="fr-footer__brand fr-enlarge-link">
								<a
									href="https://www.service-public.fr/"
									title="Accueil Service-Public.fr">
									<p
										className="fr-logo">
										République <br/>Française
									</p>
								</a>
							</div>
							<div
								className="fr-footer__content">
								<p
									className="fr-footer__content-desc">Service Public vous informe et
									vous oriente
									vers les services qui permettent de connaître vos obligations, d’exercer vos droits et de faire vos
									démarches du quotidien.
								</p>
								<p
									className="fr-footer__content-desc">
									<span>Il est édité par la
									</span>
									<a
										href="https://www.dila.premier-ministre.gouv.fr/"
										rel="noopener noreferrer"
										target="_blank"
										title="Direction de l'information légale et administrative - Nouvelle fenêtre">Direction de
										l’information
										légale et administrative
									</a>et réalisé en partenariat avec les administrations nationales et
									locales.
								</p>
								<ul
									className="fr-footer__content-list">
									<li>
										<a
											className="fr-footer__content-link"
											href="http://legifrance.gouv.fr"
											rel="noopener noreferrer"
											target="_blank"
											title="legifrance.gouv.fr - nouvelle fenêtre">legifrance.gouv.fr
										</a>
									</li>
									<li>
										<a
											className="fr-footer__content-link"
											href="http://gouvernement.fr"
											rel="noopener noreferrer"
											target="_blank"
											title="gouvernement.fr - nouvelle fenêtre">gouvernement.fr
										</a>
									</li>
									<li>
										<a
											className="fr-footer__content-link"
											href="http://service-public.fr"
											rel="noopener noreferrer"
											target="_blank"
											title="service-public.fr - nouvelle fenêtre">service-public.fr
										</a>
									</li>
									<li>
										<a
											className="fr-footer__content-link"
											href="http://data.gouv.fr"
											rel="noopener noreferrer"
											target="_blank"
											title="data.gouv.fr - nouvelle fenêtre">data.gouv.fr
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div
							className="fr-footer__bottom">
							<ul
								className="fr-footer__bottom-list">
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href="https://www.service-public.fr/P10003">Plan du site
									</a>
								</li>
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href=" https://www.service-public.fr/P10000">Accessibilité :
										partiellement conforme
									</a>
								</li>
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href="https://www.service-public.fr/P10125">Accessibilité des
										services
										en ligne
									</a>
								</li>
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href="https://www.service-public.fr/P10025">Mentions
										légales
									</a>
								</li>
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href="https://www.service-public.fr/P10001">Données
										personnelles et
										sécurité
									</a>
								</li>
								<li
									className="fr-footer__bottom-item">
									<a
										className="fr-footer__bottom-link"
										href="https://www.service-public.fr/P10050">Conditions
										générales
										d’utilisation
									</a>
								</li>
							</ul>
							<div
								className="fr-footer__bottom-copy">
								<p>
									<span>Sauf mention contraire, tous les textes de ce site sont sous
									</span>
									<a
										href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
										rel="noopener noreferrer"
										target="_blank"
										title="licence etalab-2.0 - nouvelle fenêtre">licence etalab-2.0
									</a>
								</p>
							</div>
						</div>
					</div>
					<p
						className="fr-sr-only">footer
					</p>
				</footer>
			</div>
		</div>
	)
}

export { ErreurAuthentification }

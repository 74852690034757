import React, { ReactNode, useContext } from "react"
import LayoutPrincipal from "../../ui/LayoutPrincipal/LayoutPrincipal"
import { BACKEND_URL } from "../../../services/configurationHttp"
import { UtilisateurCourantContext } from "../../pages/Authentifié/UtilisateurCourantContext"

type LayoutPrincipalProps = {
	children: ReactNode,
}

const LayoutPrincipalContextualisé: React.FunctionComponent<LayoutPrincipalProps> = ({ children }: LayoutPrincipalProps ) => {
	const utilisateurCourant = useContext(UtilisateurCourantContext)
	return (
		<LayoutPrincipal urlDeconnexion={ utilisateurCourant?`${BACKEND_URL}/logout`:null }>
			{ children }
		</LayoutPrincipal>
	)
}

export default LayoutPrincipalContextualisé

import Validation from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"

const COMMUNE_DE_NAISSANCE_REGEX = /^[- a-zœæ'—àâäçéèêëîïôöùûüÿ]+$/i

export default class CommuneDeNaissanceValidation implements Validation {
	protected labelDuChamp = "commune de naissance"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "50"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}
	
	messageErreur(valeur: string): string {
		if (valeur.trim().length === 0) {
			return MessagesErreursValidation.COMMUNE_DE_NAISSANCE_NON_RENSEIGNEE
		}
		if (valeur.length > 50) {
			return MessagesErreursValidation.COMMUNE_DE_NAISSANCE_TROP_LONGUE
		}
		if (!COMMUNE_DE_NAISSANCE_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.COMMUNE_DE_NAISSANCE_AVEC_CHIFFRE
			}
			return MessagesErreursValidation.COMMUNE_DE_NAISSANCE_AVEC_CARACTERE_SPECIAL
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

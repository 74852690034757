import React from "react"

const SurPiedDePage: React.FunctionComponent = () => {
	return (
		<div className="fr-footer__top">

			<div className="fr-container">
				<div 
					className="fr-grid-row fr-grid-row--start fr-grid-row--gutters"
					role="list"
				>

					<div 
						className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4"
						role="listitem">
						<div className="fr-footer__top-cat">Besoin d’aide pour ce service en ligne&nbsp;?</div>
						<ul className="fr-footer__top-list">
							<li>
								<a 
									className="fr-footer__top-link" 
									href="https://www.service-public.fr/particuliers/vosdroits/R16396/signaler-un-probleme"
									rel="noopener noreferrer"
									target="_blank" 
									title="Avez-vous une question ou un problème ? - Nouvelle fenêtre"
								>
									Avez-vous une question ou un problème&nbsp;?
								</a>
							</li>
							<li>
								<a 
									className="fr-footer__top-link" 
									href="https://www.service-public.fr/particuliers/vosdroits/N47#1"
									rel="noopener noreferrer" 
									target="_blank"
									title="Toute l’information sur cette démarche - Nouvelle fenêtre"
								>
									Toute l’information sur cette démarche
								</a>
							</li>
						</ul>
					</div>
					<div
						className="fr-col-xs-12 fr-col-sm-6 fr-col-md-4"
						role="listitem">
						<div className="fr-footer__top-cat">Nos engagements</div>
						<ul className="fr-footer__top-list">
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10125"
									rel="noopener noreferrer"
									target="_blank"
									title="Accessibilité de la démarche - Nouvelle fenêtre"
								>
									Accessibilité de la démarche : partiellement conforme
								</a>
							</li>
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10129"
									rel="noopener noreferrer"
									target="_blank"
									title="Données personnelles et sécurité de la démarche - Nouvelle fenêtre"
								>
									Données personnelles et sécurité de la démarche
								</a>
							</li>
							<li>
								<a
									className="fr-footer__top-link"
									href="https://www.service-public.fr/P10128"
									rel="noopener noreferrer"
									target="_blank"
									title="Conditions générales d'utilisation de la démarche - Nouvelle fenêtre"
								>
									Conditions générales d&rsquo;utilisation de la démarche
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

		</div>
	)
}

export default SurPiedDePage

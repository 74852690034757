import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation from "./Validation"

const NUMERO_TELEPHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i

export default class NumeroTelephoneValidation implements Validation {
	protected labelDuChamp = "numéro de téléphone"
	readonly attributsDeValidation = {
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if (valeur.length > 25) {
			return MessagesErreursValidation.NUMERO_DE_TELEPHONE_TROP_LONG
		}
		if (valeur.trim().length != 0) {
			if (!NUMERO_TELEPHONE_REGEX.test(valeur)) {
				return MessagesErreursValidation.NUMERO_TELEPHONE_MAUVAIS_FORMAT
			}
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


import MessagesErreursValidation from "./MessagesErreursValidation"
import Validation from "./Validation"

const COMMUNE_RESIDENCE_REGEX = /^[- a-zœæ'—àâäçéèêëîïôöùûüÿ]+$/i

export default class CommuneDeResidenceValidation implements Validation {
	protected labelDuChamp = "commune"
	readonly attributsDeValidation = {
		"aria-required": true,
		"maxLength": "50"
	}

	estValide(valeur: string): boolean {
		return !this.messageErreur(valeur)
	}

	messageErreur(valeur: string): string {
		if ((valeur === undefined) || (valeur.trim().length === 0)) {
			return MessagesErreursValidation.COMMUNE_DE_RESIDENCE_NON_RENSEIGNEE
		}
		if (valeur.length > 50) {
			return MessagesErreursValidation.COMMUNE_DE_RESIDENCE_TROP_LONGUE
		}
		if (!COMMUNE_RESIDENCE_REGEX.test(valeur)) {
			const contientUnChiffre = /\d/
			if (contientUnChiffre.test(valeur)) {
				return MessagesErreursValidation.COMMUNE_DE_RESIDENCE_AVEC_CHIFFRE
			}
			return MessagesErreursValidation.COMMUNE_DE_RESIDENCE_AVEC_CARACTERE_SPECIAL
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}


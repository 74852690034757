import Validation, { ValidationAttributes } from "./Validation"
import MessagesErreursValidation from "./MessagesErreursValidation"


export default class PieceJustificativeValidation implements Validation {
	protected labelDuChamp = "piece justificative"
	readonly attributsDeValidation: ValidationAttributes = {
		"aria-required": true
	}

	estValide([id, nom]: string[]): boolean {
		return !this.messageErreur([id, nom])
	}

	messageErreur([id, nom]: string[]): string {
		if ((id === "") && (nom === "")) {
			return MessagesErreursValidation.PIECE_JUSTIFICATIVE_OBLIGATOIRE
		}
		return MessagesErreursValidation.PAS_D_ERREUR_DE_VALIDATION
	}
}

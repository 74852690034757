import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"

const Accueil: React.FunctionComponent = () => {
	const urlDestination = "/demarche"

	useEffect(() => {
		document.title = "Avant de commencer votre demande d’inscription sur les listes électorales - service-public.fr"
	}, [])

	return (
		<div className="sp-demarche-step">
			<h2 className="sp-demarche-step-titre">
				<span className="sp-demarche-step-titre-nombre">
					<span
						aria-hidden="true"
						className="ri-arrow-left-right-line"
					>
					</span>
				</span>
				<span className="sp-demarche-step-titre-label">Bienvenue</span>
			</h2>
			<div className="fr-callout fr-mb-3w sp-callout-recap">
				<span
					aria-hidden="true"
					className="ri-arrow-left-right-line"
				>
				</span>
				<p className="fr-text">
					Vous êtes <strong>citoyen français ou d’un autre pays de l’Union européenne</strong> et vous souhaitez <strong>vous inscrire</strong> sur les listes électorales <strong>pour voter en France</strong>, par exemple si vous déménagez.
				</p>
			</div>
			<h2 className="sp-icon-text fr-text--lg fr-text--regular sp-accent fr-mt-6w fr-mb-3w">
				<span
					aria-hidden="true"
					className="ri-file-user-line"
				>
				</span>
				Préparez vos documents
			</h2>
			<p><strong>Pendant cette démarche vous aurez à transmettre plusieurs documents :</strong></p>
			<ul>
				<li>Un justificatif de <strong>votre identité</strong> (par exemple votre carte nationale d’identité)</li>
				<li>Un justificatif de <strong>votre lien avec la commune de vote</strong> (par exemple votre facture d’électricité comme justificatif de domicile)</li>
			</ul>
			<p>Et si vous êtes <strong>citoyen d’un autre pays de l’Union européenne</strong> :</p>
			<ul>
				<li>Une&nbsp;
					<a
						href="https://www.service-public.fr/simulateur/calcul/DeclarationInscriptionEuropeen"
						rel="noopener noreferrer"
						target="_blank"
						title="Déclaration sur l'honneur - Nouvelle fenêtre">déclaration sur l’honneur
					</a> précisant <strong>votre situation électorale</strong>
				</li>
			</ul>
			<p className="fr-mt-3w fr-mb-6w">
				<a
					className="fr-btn fr-btn--secondary sp-btn-icon"
					href="https://www.service-public.fr/particuliers/vosdroits/N47#1"
					rel="noopener noreferrer"
					target="_blank"
					title="Et dans ma situation ? - Nouvelle fenêtre"
				>
					<span
						aria-hidden="true"
						className="ri-user-follow-line ri--lg"
					>
					</span>
					Et dans ma situation ?
				</a>
			</p>
			<ul className="fr-btns-group fr-mb-2w fr-mt-6w">
  			<li>
					<NavLink
						className="fr-btn fr-col-12"
						to={ urlDestination }
					>
						Commencer
					</NavLink>
		  	</li>
			</ul>
		</div>

	)
}

export default Accueil

import React, { ChangeEvent, RefObject, SyntheticEvent, useState } from "react"
import classNames from "classnames"
import Validation from "../../../services/ValidateurDeDemarche/validateursParChamp/Validation"

type ChampTextuelProps = {
	id: string
	descriptionId?: string
	label: string,
	validation?: Validation
	name: string,
	autoComplete?: string
	valeur?: string,
	messageErreurFormulaire?: string,
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: ChangeEvent<HTMLInputElement>) => void
	ref?: RefObject<HTMLInputElement>,
	texteBoutonSuppression?: string,
	onClickBoutonSuppression?: (event: SyntheticEvent) => void,
	afficherBoutonSuppression?: boolean,
	texteAide?: string
}

const ChampTextuelAvecRef: React.ForwardRefRenderFunction<HTMLInputElement, ChampTextuelProps> = ({ id, descriptionId, label, validation, name, autoComplete, valeur = "", messageErreurFormulaire, onChange, texteBoutonSuppression, onClickBoutonSuppression, afficherBoutonSuppression, onBlur, texteAide }: ChampTextuelProps, ref: any) => {
	const errorId = `error-${id}`
	const [messageErreur, setMessageErreur] = useState<string | undefined>()
	const [aEteModifie, setAEteModifie] = useState<boolean>(false)

	const attributsDeValidation = validation ? validation.attributsDeValidation : {}
	const messageErreurAffiche = aEteModifie ? messageErreur : messageErreurFormulaire

	function verifierValeur(e: any) {
		setAEteModifie(true)
		if (validation) {
			if (validation.estValide(e.target.value)) {
				setMessageErreur(undefined)
				e.target.setCustomValidity("")
			} else {
				setMessageErreur(validation.messageErreur(e.target.value))
				e.target.setCustomValidity(validation.messageErreur(e.target.value))
			}
		}

		if (onBlur)
			onBlur(e)
	}

	const classesDuGroupe = classNames("fr-input-group", {
		"fr-input-group--error": Boolean(messageErreurAffiche)
	})
	const classesInput = classNames("fr-input", {
		"fr-input--error": Boolean(messageErreurAffiche)
	})

	return (
		<div className={ classesDuGroupe }>
			<label
				className="fr-label"
				htmlFor={ id }
			>
				{ label }
			</label>
			{ texteAide &&
			<p
				className="fr-hint-text"
				id={ descriptionId }
			>
				{ texteAide }
			</p>
			}
			<div className="sp-input-group">
				<input
					className={ classesInput }
					{ ...attributsDeValidation }
					aria-describedby={ descriptionId ? [descriptionId, errorId].join(" ") : errorId }
					aria-invalid={ Boolean(messageErreurAffiche) || undefined }
					autoComplete={ autoComplete }
					id={ id }
					name={ name }
					onBlur={ verifierValeur }
					onChange={ onChange }
					ref={ ref }
					type="text"
					value={ valeur }
				/>
				{ texteBoutonSuppression && afficherBoutonSuppression &&
				<button
					className="btn-in-input"
					onClick={ onClickBoutonSuppression }
					title={ texteBoutonSuppression }
					type="button">
					<span className="fr-fi-close-line"></span>
					<span className="fr-sr-only">{ texteBoutonSuppression }</span>
				</button>
				}
			</div>
			<p
				className="fr-error-text"
				hidden={ Boolean(!messageErreurAffiche) }
				id={ errorId }
				role="alert"
			>
				{ messageErreurAffiche }
			</p>
		</div>
	)
}

export default React.forwardRef(ChampTextuelAvecRef)
